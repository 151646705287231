@import 'src/style/default_variables';

.post-page__wrapper {
  min-width: 320px;
}

.post-rows__container {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.post-content__container {
  margin: 40px auto 0;
  width: 100%;
  padding: 0 20px;
  scroll-behavior: smooth;
}

.post-content {
  position: relative;
  width: 100%;
  max-width: 1420px;
  margin: 0 auto;
}

.post-root-content_container {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.post-description_frame {
  width: 100%;
  margin-top: 80px;
  color: $color_primary_black;
}

.post-description_title {
  font-weight: 500;
  font-size: 28px;
  line-height: 120%;
}

.post-description-text {
  max-width: 820px;
  margin-top: 20px;
  font-size: 16px;
  line-height: 22px;
}

@media (max-width: 1420px) {
  .post-page__wrapper {
    padding-bottom: 100px;

    &.is-my-post {
      padding-bottom: 0;
    }
  }

  .post-root-content_container {
    flex-wrap: wrap;
    gap: 20px;
  }

  .post-description_frame {
    margin-top: 60px;
  }

  .post-description_title {
    font-size: 24px;
  }
}

@media (max-width: 1000px) {
  .post-description_frame {
    margin-top: 40px;
  }
}

@media (max-width: 768px) {
  .post-content__container {
    margin-top: 20px;
  }

  .post-root-content_container {
    margin-top: 20px;
    gap: 10px;
  }

  .post-description_title {
    font-size: 20px;
  }

  .post-description-text {
    margin-top: 10px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .post-content__container {
    padding: 0 14px;
  }

  .post-description_title {
    font-size: 18px;
  }
}
