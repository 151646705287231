@import 'src/style/default_variables';

.post-fields__frame {
  width: 100%;
  margin-top: 80px;
}

.post-fields__frame-title {
  font-weight: 500;
  font-size: 28px;
  line-height: 120%;
  color: $color_primary_black;
}

.post-fields__container {
  margin-top: 20px;
}

.post-field__field-container {
  width: 100%;
  margin: 2px 0;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.field-title-container {
  width: calc((100% - 112px) / 8 * 3 + 32px);
  display: flex;
  align-items: flex-end;
  transition: all 0.3s;
}

.field-title,
.field-options {
  font-weight: 300;
  font-size: 16px;
  color: #110b11;
}

.field-options {
  width: calc((100% - 112px) / 8 * 5 + 64px);
  font-weight: 400;
  color: $color_accent_red;
  opacity: 0.7;
}

.field-title-dots {
  border-bottom: 1px dotted rgba(17, 11, 17, 0.2);
  flex: 1 1;
  margin-left: 8px;
}

@media (max-width: 1420px) {
  .post-fields__frame {
    margin-top: 60px;
  }

  .post-fields__frame-title {
    font-size: 24px;
  }
}

@media (max-width: 1000px) {
  .post-fields__frame {
    margin-top: 40px;
  }
}

@media (max-width: 768px) {
  .post-fields__frame-title {
    font-size: 20px;
  }

  .post-fields__container {
    margin-top: 10px;
  }

  .field-title,
  .field-options {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .post-fields__frame-title {
    font-size: 18px;
  }

  .field-title-container,
  .field-options {
    width: 50%;
  }
}
