.second-step {
  min-height: 455px;
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;

}

.upload-container {
  display: flex;
  gap: 20px;
  max-height: 340px;
}

.upload-description {
  margin-top: 5px;
  font-size: 14px;
  line-height: 130%;
  color: #110B11;
  opacity: 0.7;
}

.cards-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 340px;
  max-height: 340px;
  transition: all .3s;
}

.cards-container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.upload-frame {
  cursor: pointer;
  max-width: 700px !important;
  width: 98%;
  height: 339px;
  border: 3px dashed #F0F0F0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    max-width: 106px;
    max-height: 91px;
    width: 100%;
    height: 100%;
  }
}

.input-price-container {
  display: flex;
  flex-direction: column;

  & div {
    margin-bottom: 4px;
  }
}

.input-price {
  transition: all .3s;

  &.false {
    margin-bottom: 48px;
  }
}

@media (max-width: 1200px) {
  .upload-container {
    flex-direction: column;
    max-height: none;
  }

  .cards-container {
    max-width: 700px;
    max-height: none;
    height: auto;
    width: auto;
    flex-wrap: wrap;
    flex-direction: row;
  }
}
