@import '../reviewsModals.scss';

.view__reviews__container {
  @extend .reviews-container;

  .view__reviews__modal {
    @extend .reviews-modal;

    .view__reviews__modal-header {
      @extend .reviews-modal-header;

      .view__reviews__modal-header__title {
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
      }

      .view__reviews__modal-header__close {
        cursor: pointer;
      }
    }

    .view__reviews__modal-body {
      @extend .reviews-modal-body;
      gap: 45px;

      .view__reviews__modal-body__stats {
        width: 100%;
        gap: 60px;

        display: flex;
        flex-direction: row;
        align-items: center;

        span {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 10px;
        }
      }

      .view__reviews__modal-body__posts {
        width: 100%;

        flex: 1;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: start;
        overflow: auto;
      }
    }

    .view__reviews__modal-footer {
      width: 100%;
      display: flex;
      align-items: end;
      justify-content: center;
      flex-direction: row;
      gap: 20px;

      flex-shrink: 0;
    }
  }
}

@media (max-width: $breakpoint_sm) {
  .view__reviews__container {
    .view__reviews__modal {
      .view__reviews__modal-body {
        .view__reviews__modal-body__stats {
          gap: 25px;
        }
      }
    }
  }
}

@media (max-width: $breakpoint_s) {
  .view__reviews__container {
    .view__reviews__modal {
      .view__reviews__modal-body {
        .view__reviews__modal-body__stats {
          flex-direction: column;
          gap: 25px;

          span {
            align-items: center;
            gap: 5px;
          }
        }

        .view__reviews__modal-body__posts {
          max-height: calc(100vh - 450px);
        }
      }
    }
  }
}

@include breakpoint_m;
