.chat__user-list-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #f2f2f2;
  border-radius: 10px 0 0 10px;

  flex: 1 0 390px;
  min-width: 0;
  border-right: 1px solid #ededed;

  .chat__search-bar {
    width: 90%;
    max-height: 68px;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 10px;
    box-sizing: border-box;
  }

  .chat__users-list {
    width: 100%;
    height: 100%;
    border-top: 1px solid #f2f2f2;
    overflow-y: auto;
    border-radius: 0 0 0 10px;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #f2f2f2;
      border-radius: 10px;
      border: 3px solid #f2f2f2;
    }

    .chat__user {
      max-height: 80px;
      width: 100%;
      display: flex;
      padding: 10px 20px;
      border-bottom: 1px solid #f2f2f2;
      cursor: pointer;
      box-sizing: border-box;
      transition: all 0.3s;

      &:hover {
        background-color: #ffffff;
      }

      .chat__user-avatar__container {
        min-width: 60px;
        max-width: 60px;
        width: 100%;
        border-radius: 50%;
        box-sizing: border-box;
        overflow: hidden;
      }

      .chat__user-avatar {
        max-width: 60px;
        width: 100%;
        height: 60px;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 50%;
        border: 1px solid rgb(0, 0, 0, 0.05);
        background: #f2f2f2;
        box-sizing: border-box;
        cursor: pointer;

        & h1 {
          font-weight: 500;
          font-size: 24px;
          line-height: 33px;
          color: #dd2006;
        }
      }

      .chat__user-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 20px;
        gap: 10px;

        div {
          height: 50%;
          font-family: 'Noto Sans', sans-serif;
          color: #110b11;

          &.chat__user-name {
            font-weight: 500;
            font-size: 18px;
            line-height: 25px;
          }
        }
      }
    }
  }
}

.chat__time-last-message {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.chat__user-last-message,
.chat__user-last-time {
  display: flex;
  align-items: end;

  padding-bottom: 5px;
  opacity: 0.8;
  font-size: 14px;
  line-height: 19px;
}

.chat__unread-messages-count {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1000px;
  background-color: #dd2006;
  color: #ffffff;
  margin-left: auto;
}

@media (max-width: 768px) {
  .chat__user-list-container {
    border-radius: 10px;
    height: 100%;
    overflow: hidden;

    &.hide {
      display: none;
    }
  }
}
