@import '../../../style/default_variables';

.search-field-frame {
  height: 40px;
  width: 600px;
  transition: all 0.3s;
  z-index: 5 !important;
}

.search-field-container {
  width: 100%;
  height: 100%;

  display: flex;

  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 10px;

  position: relative;
  z-index: 5 !important;

  & input {
    padding-left: 10px;
    outline: none;
    border: none;
    flex-grow: 1;
    font-size: 16px;
    line-height: 130%;
    color: #110b11;
    opacity: 0.8;
    background: transparent;

    &::placeholder {
      font-size: 14px;
    }
  }
}

.search-btn {
  opacity: 0.2;
  display: flex;
  align-items: center;
  margin-right: 10px;
  transition: 0.3s linear;

  svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }

  &:hover {
    opacity: 0.6;
  }
}

.search-field-overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(17, 11, 17, 0.5), rgba(17, 11, 17, 0.5));
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0s;

  &.show {
    z-index: 3;
    opacity: 1;
    visibility: visible;
  }
}

.search-btn__small-resolution {
  display: none;
}

@media (max-width: 1000px) {
  .search-field-frame {
    opacity: 0;
    visibility: hidden;
    width: auto;
    left: 0;
    top: -100px;
    bottom: 0;
    right: 0;
    padding-left: 20px;
    padding-right: 20px;
    position: absolute;
    margin: 0 auto;

    &.show {
      top: 12px;
      opacity: 1;
      visibility: visible;
      z-index: 10 !important;
    }
  }

  .search-field-container {
    max-width: none;
  }

  .search-btn__small-resolution {
    cursor: pointer;
    margin-left: auto;
    display: block;
    width: 24px;
    height: 24px;

    img {
      width: 24px;
      height: 24px;
    }
  }
}
