@import 'src/style/default_variables';

.post-page-actions__container {
  width: 100%;
  min-width: 320px;
  min-height: 798px;
  margin-top: 40px;
  padding: 0 20px;
}

.post-actions-page {
  max-width: 1420px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.steps-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  padding: 20px 30px;
  margin: 0 auto;
  transition: all 0.3s ease-in-out;
}

.steps-container.hide {
  display: none;
}

.first-line_steps-container,
.second-line_steps-container {
  width: 162px;
  margin: auto 10px;
  height: 1.5px;
  background: linear-gradient(to right, #dd2006 50%, #f0f0f0 50%) 100%;
  background-size: 200% 100%;
  transition: all 0.45s;
}

.first-line_steps-container.active,
.second-line_steps-container.active {
  background-position: 0 100%;
}

.content_steps-container {
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #110b11;
}

.content_steps-container.active,
.content_steps-container.complete {
  color: #dd2006;
}

.circle_steps-container {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  border: 1px solid #f0f0f0;
  border-radius: 100px;
  transition: all 0.3s;
}

.circle_steps-container.active,
.circle_steps-container.complete {
  border: 1px solid #dd2006;
  transition: all 0.3s;
}

.circle_steps-container.complete {
  background: #dd2006;
}

.move-buttons_container {
  width: 100%;
  height: 60px;
  display: flex;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;

  &.show {
    opacity: 1;
    visibility: visible;
  }

  p {
    cursor: pointer;
    transition: all 0.45s;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #dd2006;

    &:hover {
      color: $color_primary_black;
    }
  }
}

.move-buttons_container.to-second {
  justify-content: right;
}

.move-buttons_container.to-third {
  justify-content: space-between;
  margin-top: 40px;
  transition: all 0.45s;
  opacity: 1;
  visibility: visible;

  & :last-child {
    opacity: 0;
    visibility: hidden;

    &.show {
      opacity: 1;
      visibility: visible;
    }
  }
}

.move-buttons_container.back-to-second {
  opacity: 1;
  visibility: visible;
  justify-content: space-between;
}

@media (max-width: 1200px) {
  .first-line_steps-container,
  .second-line_steps-container {
    width: 100px;
  }
}

@media (max-width: 800px) {
  .submit-buttons-container {
    flex-direction: column;
    gap: 20px;
  }

  .first-line_steps-container,
  .second-line_steps-container {
    width: 50px;
  }

  .input-name-container,
  .selection-category-container,
  .menu-categories {
    width: auto;
  }

  .steps-container {
    p {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .post-page-actions__container {
    margin-top: 20px;
  }
}

@media (max-width: 700px) {
  .first-line_steps-container,
  .second-line_steps-container {
    display: none;
  }
}

@media (max-width: $breakpoint_s) {
  .post-page-actions__container {
    padding: 0 14px;
  }

  .move-buttons_container {
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center !important;
  }
}
