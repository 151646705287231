@import 'src/style/default_variables';

.my-posts-card__frame {
  width: 31.5%;
  margin: 0 10px 20px;
  padding: 20px;
  border-radius: 10px;
  background: $color_white;
  transition: all 0.3s;

  &:hover {
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  }

  &.show {
    display: block;
  }
}

.my-posts-card__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
  padding-bottom: 10px;
  border-bottom: 1px solid $color_tertiary_gray;
}

.my-posts-card__date-info {
  padding: 5px 20px;
  display: flex;
  border-radius: 10px;
  align-items: center;
  background: $color_tertiary_gray;

  font-size: 12px;
}

.my-posts-card__date-info-text {
}

.my-posts-card__root-info {
  cursor: pointer;

  &:hover {
    .my-posts-card__price,
    .my-posts-card__text.title {
      color: $color_accent_red;
    }
  }
}

.my-posts-card__image-views__container {
  display: flex;
  justify-content: space-between;
}

.my-posts-card__image {
  width: 320px;
  height: 150px;
  border: 2px solid $color_tertiary_gray_dark;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.3s;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.my-posts-card__views {
  margin-left: 40px;
}

.my-posts-card__views__item {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  margin-bottom: 10px;

  div {
    justify-self: end;
  }

  svg {
    width: 24px;
    height: 24px;
  }
}

.my-posts-card__text {
  font-size: 16px;
  line-height: 22px;
  color: $color_primary_black;
  opacity: 0.7;
  transition: all 0.3s;

  &.title {
    opacity: 1;
  }

  &.date {
    font-size: 14px;
  }
}

.my-posts-card__price {
  margin-top: 5px;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  transition: all 0.3s;
}

@media (max-width: $breakpoint_l) {
  .my-posts-card__frame {
    width: 47.5%;
  }

  .my-posts-card__views {
    margin-left: 20px;
  }
}

@media (max-width: 848px) {
  .my-posts-card__frame {
    width: 46.5%;
  }
}

@media (max-width: $breakpoint_m) {
  .my-posts-card__frame {
    width: 98%;
  }

  .my-posts-card__image {
    width: 78%;
    height: 200px;
  }

  .my-posts-card__views__item {
    font-size: 14px;
  }
}

@media (max-width: $breakpoint_s) {
  .my-posts-card__text {
    font-size: 14px;
    line-height: 19px;
  }

  .my-posts-card__image {
    height: 160px;
  }
}
