.posts-row__container {
  width: 100%;
  padding: 0 20px;
  margin-top: 100px;
}

.posts-row {
  position: relative;
  width: 100%;
  max-width: 1420px;
  margin: 0 auto;
}

.posts-row__title-container {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  h3 {
    cursor: pointer;
  }

  div {
    margin-left: 10px;
    rotate: -90deg;
  }
}

.posts-row__title {
  color: #110b11;
  font-size: 28px;
  font-weight: 700;
}

.posts-row__content {
  position: relative;
  width: 100%;
  margin-top: 20px;
  display: flex;
  gap: 20px;
  overflow-y: hidden;
  scroll-behavior: smooth;

  .swiper-slide {
    opacity: 1;
    height: auto !important;

    &::before,
    &::after {
      background: none;
    }
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.left-button {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: -70px;
  transform: translate(0, 50%);
  z-index: 1;
  width: 48px;
  height: 48px;
  rotate: 180deg;

  img {
    width: 48px;
    height: 48px;
  }
}

.right-button {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: -70px;
  transform: translate(0, -50%);
  z-index: 1;
  width: 48px;
  height: 48px;

  img {
    width: 48px;
    height: 48px;
  }
}

@media (max-width: 1580px) {
  .right-button,
  .left-button {
    display: none;
  }
}

@media (max-width: 1420px) {
  .posts-row__title {
    font-size: 24px;
  }

  .posts-row__container {
    margin-top: 60px;
  }
}

@media (max-width: 1000px) {
  .posts-row__container {
    margin-top: 40px;
  }
}

@media (max-width: 768px) {
  .posts-row__title {
    font-size: 20px;
  }

  .posts-row__content {
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .posts-row__title {
    font-size: 18px;
  }

  .posts-row__container {
    padding: 0 14px;
  }

  .posts-row__content {
    gap: 14px;
  }
}
