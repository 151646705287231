.rating__digits {
  display: flex;
  flex-direction: row;
  align-items: baseline;

  font-size: 18px;
  font-weight: 600;
  font-family: 'Noto Sans', sans-serif;
  color: #3c3c3b;
}
