.email_frame {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 40px;
  transition: all .2s;
}

.current-email_container {
  max-width: 460px;
  width: 100%;
  font-size: 16px;
  line-height: 130%;
  color: rgba(17, 11, 17, 0.8);
  gap: 5px;
  display: flex;
  flex-wrap: wrap;

  p {
    margin-bottom: 5px;
  }

  & :last-child {
    opacity: 0.8;
    height: fit-content;
    text-decoration: underline;
  }
}

@media(max-width: 1060px) {
  .email_frame {
    flex-direction: column;
    align-items: center;
    justify-content: start;
    flex-wrap: nowrap;
  }
}
