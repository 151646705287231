.my-profile-frame {
  min-width: 320px;
  max-width: 1200px;
  width: 100%;
  margin: 40px auto 0;
  position: relative;
  transition: all 0.3s;
}

.profile-tabs_container {
  padding: 0 20px;
}

.my-profile-content-frame {
  max-width: 1200px;
  min-height: 620px;
  margin: 0 auto;
  padding: 80px 20px 0 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

@media (max-width: 768px) {
  .my-profile-frame {
    margin-top: 20px;
  }
}

@media (max-width: 480px) {
  .my-profile-content-frame {
    padding: 80px 14px 14px;
  }
}
