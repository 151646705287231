@import '../../../../style/default_variables';
@import '../reviewsModals.scss';

.add__review-container {
  @extend .reviews-container;

  .add__review-modal {
    @extend .reviews-modal;

    .add__review-modal-header {
      @extend .reviews-modal-header;

      @extend .reviews-modal-header__title;
      @extend .reviews-modal-header__close;
    }

    .add__review-modal-body {
      @extend .reviews-modal-body;
      gap: 50px;

      .add__review-modal-body__user {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: start;
        justify-content: space-between;

        background-color: $color_white;
        padding: 10px 20px;
        border-radius: 10px;

        transition: all 0.2s linear;

        &:hover {
          box-shadow: 0 0 10px 0px #ededed;
        }

        .add__review-modal-body__user-info {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 20px;
          cursor: pointer;

          div {
            display: flex;
            flex-direction: column;
            gap: 5px;
          }

          .add__review-modal-body__user-image {
            width: 45px;
            height: 45px;
            border-radius: 50%;
            border: 1px solid $color_tertiary_gray;
          }

          .add__review-modal-body__user-name {
            font-size: 16px;
            font-weight: 500;
          }

          .add__review-modal-body__user-post {
            text-decoration: none;
            color: $color_primary_black;
            font-size: 16px;
            font-weight: 400;
            opacity: 0.8;
          }
        }

        .add__review-modal-body__user-date {
          font-size: 14px;
          font-weight: 400;
          opacity: 0.8;
        }
      }

      .add__review-modal-body__rate,
      .add__review-modal-body__tags,
      .add__review-modal-body__comment {
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        gap: 20px;

        p {
          font-size: 16px;
          font-weight: 400;
          font-family: 'Noto Sans', sans-serif;
          color: #3c3c3b;
        }

        .add__review-modal-body__tags-chips {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
          gap: 10px;
        }
      }

      .add__review-modal-body__comment {
        .add__review-modal-body__comment-input {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: start;
          gap: 10px;
          resize: none;
          background-color: $color_white;
          border: none;
          border-radius: 10px;
          outline: none;
          padding: 10px;
          min-height: 100px;

          &::placeholder {
            opacity: 0.3;
            font-family: 'Noto Sans', sans-serif;
            font-size: 14px;
          }
        }
      }
    }

    .add__review-modal-footer {
      width: 100%;
      display: flex;
      align-items: end;
      justify-content: center;
      flex-direction: row;
      gap: 20px;

      flex-shrink: 0;
    }
  }
}

@media (max-width: $breakpoint_s) {
  .modal-container .modal .modal-body .modal-body__stats {
    flex-direction: column;
    gap: 25px;

    span {
      align-items: center;
      gap: 5px;
    }
  }
}

@include breakpoint_m;
