.rating__stars-view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;

  .stars {
    display: flex;
    flex-direction: row;

    .star {
      transition: 0.15s ease-in-out;
      scale: 1;
    }

    .star:hover {
      transition: 0.2s ease-in-out;
      scale: 1.1;
    }
  }

  .rate {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    p:first-child {
      font-size: 1.5rem;
      font-weight: 400;
    }

    span {
      font-size: 1.2rem;
      margin-inline: 3px;
    }
  }
}

.rating__stars-add-frame {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .rating__stars-add {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;

    .stars {
      cursor: pointer;
      display: flex;
      flex-direction: row;

      .star {
        transition: 0.15s ease-in-out;
        scale: 1;
      }

      .star:hover {
        transition: 0.2s ease-in-out;
        scale: 1.1;
      }
    }

    .rate {
      display: flex;
      flex-direction: row;
      align-items: baseline;

      p:first-child {
        font-size: 1.5rem;
        font-weight: 400;
      }

      span {
        font-size: 1.2rem;
        margin-inline: 3px;
      }
    }
  }

  .rating__stars-add__status {
    font-family: 'Noto Sans', sans-serif;
    font-size: 20px;
    font-weight: 500;
  }
}
