@import '../../../../style/default_variables';

.dots-button {
  min-width: 35px;
  min-height: 35px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  background: transparent;
  transition: background 0.2s;

  &.clicked {
    background: $color_tertiary_gray_dark;
  }
}

.my-posts-card__action-buttons svg {
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.edit-my-post-button,
.delete-my-post-button {
  min-width: 150px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  background: $color_white;
  transition: color, background 0.2s;
  color: $color_primary_black;

  p {
    font-size: 16px;
  }

  &:hover {
    background: $color_gray;
  }
}

@media (max-width: $breakpoint_m) {
  .dots-button {
    min-width: 30px;
    min-height: 30px;
  }
}
