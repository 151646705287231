@import 'src/style/default_variables';

.filter-posts__frame {
  width: 100%;
  //min-width: 940px;
}

.filter-posts__container {
  min-height: 80vh;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-content: flex-start;
}

.filter-no-posts {
  text-align: center;
  margin: 20px auto;
  font-size: 24px;
  line-height: 130%;
  color: $color_primary_black;

  &.bold {
    font-size: 32px;
    font-weight: 500;
  }
}

@media (max-width: 1000px) {
  .filter-posts__frame {
    min-width: auto;
    width: 100%;
  }

  .filter-posts__container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 14px;
    grid-row-gap: 14px;
    grid-auto-rows: min-content;
  }
}

@media (max-width: 768px) {
  .filter-no-posts {
    font-size: 16px;

    &.bold {
      font-size: 24px;
    }
  }
}

@media (max-width: 768px) {
  .filter-posts__container {
    gap: 14px;
  }

  .filter-posts__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 480px) {
  .filter-posts__frame {
    min-height: 280px;
  }

  .filter-posts__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
