@import '../../style/default_variables';
@font-face {
  font-family: 'BPG LE Studio Caps';
  src: url('../../fonts/bpg_le_studio/bpg_le_studio_02_caps.ttf') format('truetype');
}

.dashboard__container {
  position: relative;
  margin: 0 auto;
  min-width: 320px;
  width: 100%;
  scroll-behavior: smooth;
}

.categories__container {
  min-width: 320px;
  width: 100%;

  display: flex;
  justify-content: center;

  background-color: #f5f5f5;

  .categories__frame {
    display: flex;
    align-items: center;

    width: 100%;
    height: 40px;

    max-width: 1420px;
    margin: 0 20px;

    position: relative;

    overflow: hidden;
    scroll-behavior: smooth;
    transition: all 0.3s;

    .categories__line {
      max-width: 1420px;
      margin: 0 auto;
      display: flex;
      gap: 2px;

      overflow: auto;
      transition: all 0.3s;
      scroll-behavior: smooth;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }

    .ml-127:first-child {
      margin-left: 127px;
    }
    .ml-87:first-child {
      margin-left: 87px;
    }

    .category-button {
      background-color: #f5f5f5;

      color: #3c3c3b;
      padding: 12px;

      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      display: flex;
      align-items: center;
      box-sizing: border-box;

      white-space: nowrap;
      cursor: pointer;
      transition: all 0.3s;

      .burger-text {
        margin-left: 5px;
        margin-right: 10px;
      }

      &.active,
      &:hover {
        background-color: #e5e5e5;
      }
    }

    .category-button__skeleton {
      padding: 0;
      margin: 6px 2px;
      border-radius: 100px;
    }

    .static-categories-buttons {
      position: absolute;

      height: 40px;
      padding: 0;

      top: 0;
      left: 0;

      display: flex;
      align-items: center;
      justify-content: end;

      color: #3c3c3b;

      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .blacking-block {
      height: 40px;
      width: 116px;

      top: 0;

      display: flex;
      align-items: center;
      justify-content: end;
      position: absolute;

      background: linear-gradient(270deg, #f5f5f5 0%, rgba(249, 249, 249, 0) 100%);
      cursor: pointer;
      transition: all 0.3s;

      &.right-side {
        right: 0;
      }

      &.left-side {
        left: 77px;
        height: 40px;
        transform: rotate(180deg);
      }

      &.l-127 {
        left: 127px !important;
      }
      &.l-87 {
        left: 87px !important;
      }

      svg {
        transition: all 0.3s;
        width: 18px;
        height: 18px;
      }
    }
  }
}

.categories__container-mobile {
  min-width: 320px;
  width: 100%;
  padding: 20px;

  display: flex;
  justify-content: space-between;
  align-items: baseline;

  background-color: #ffffff;
}

@media (max-width: 480px) {
  .categories__container-mobile {
    padding: 14px;
  }
}

.first-banner__container {
  height: 340px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.3s;

  .first-banner__content {
    max-width: 1420px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    align-content: flex-start;
    flex-direction: column;

    padding: 50px;
    margin-left: 50px;
    margin-bottom: 50px;

    .banner-text {
      transition: all 0.3s;
      font-size: 36px;
      font-weight: 700;
      color: #ffffff;
      text-align: left;
      max-width: 700px;
    }

    .mw-800 {
      max-width: 800px;
      margin-top: 30px;
      margin-left: 100px;
    }

    .banner-button {
      font-size: 16px;
      margin-top: 40px;
      width: 180px;
      height: 50px;
    }
  }
}

.promo-cards__container {
  width: 100%;
  padding: 0 20px;
  margin-top: 40px;
}

.promo-cards {
  width: 100%;
  max-width: 1420px;
  margin: 0 auto;
  display: flex;
  gap: 20px;
}

.promo-card {
  background-color: #3c3c3b;
  padding: 0 10px;
  width: 25%;
  height: 170px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 5px;

  .promo-text {
    text-align: center;
    color: #f5f5f5;
  }

  img {
    width: 64px;
    height: 64px;
  }

  h2 {
    font-size: 20px;
    font-weight: 700;
  }
}

.blog-card {
  padding: 0 10px;
  background-position: center;
  background-size: cover;
  width: 25%;
  aspect-ratio: 1 / 1;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 0;
    border-radius: 20px;
    transition: background-color 0.3s;
  }

  .blog-text {
    text-align: center;
    color: #f5f5f5;
    z-index: 1;
  }

  &:hover:before {
    background-color: rgba(0, 0, 0, 0);
  }
}

.second-banner__container {
  background-image: url('../../img/dashboard-img/discover_something_new_banner.svg');
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 250px;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: $breakpoint_l) {
  .second-banner__container {
    background-size: cover;
    background-image: url('../../img/dashboard-img/discover_something_new_banner_small.svg');
  }
}

.second-banner__container-bg {
  background-color: #3c3c3b;
}

.second-banner {
  width: 100%;
  max-width: 1420px;
  padding: 40px 80px;
}

.discover__title {
  color: #fff;
  font-size: 48px;
  font-weight: 700;
  line-height: normal;
}

.discover__learn-more {
  margin-top: 20px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;

  img {
    width: 24px;
    height: 24px;
  }
}

.blog__container {
  width: 100%;
  padding: 50px 20px;
}

.blog__content {
  width: 100%;
  max-width: 1420px;
  margin: 0 auto;
}

.blog-title {
  color: #110b11;
  font-size: 24px;
  font-weight: 700;
}

.blog-cards {
  width: 100%;
  margin-top: 20px;
  display: flex;
  gap: 20px;
}

.dashboard-fab {
  position: fixed;
  bottom: 40px;
  right: 40px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  z-index: 2;

  .MuiFab-root {
    width: 44px;
    height: 44px;
    background: #110b11;

    &:hover {
      background: #110b11;
    }
  }

  img {
    width: 24px;
    height: 24px;
  }

  &.show {
    visibility: visible;
    opacity: 1;
  }
}

@media (max-width: 1420px) {
  .categories-row {
    .categories-row__container {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .first-banner__container {
    background-size: auto;
    height: 280px;

    .first-banner__content {
      .banner-text {
        max-width: 650px;
      }

      .mw-800 {
        max-width: 800px;
        margin-top: 30px;
        margin-left: 100px;
      }

      .banner-button {
        margin-top: 20px;
      }
    }
  }

  .promo-card {
    font-size: 18px;
  }

  .blog__container,
  .second-banner__container {
    margin-top: 60px;
  }

  .second-banner__container {
    height: auto;
  }

  .discover__title {
    font-size: 36px;
  }
}

@media (max-width: 1000px) {
  .first-banner__container {
    background-size: cover;
    height: 220px;

    .first-banner__content {
      padding: 0 20px;

      .banner-text {
        font-size: 32px;
      }

      .banner-button {
        height: 40px;
      }
    }
  }

  .blog__container,
  .second-banner__container {
    margin-top: 40px;
  }
}

@media (max-width: 768px) {
  .first-banner__container {
    height: 230px;
    background-size: cover;

    .first-banner__content {
      align-items: flex-start;

      .banner-text {
        max-width: 230px;
        font-size: 24px;
      }

      .mw-800 {
        max-width: 700px;
        margin-top: 10px;
        margin-left: 0;
      }

      .banner-button {
        width: 150px;
      }
    }
  }

  .promo-cards,
  .blog-cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .second-banner {
    padding: 40px 20px;
  }

  .discover__title {
    font-size: 24px;
  }

  .discover__learn-more {
    gap: 5px;
    margin-top: 10px;
    font-size: 14px;

    img {
      width: 16px;
      height: 16px;
    }
  }

  .blog-cards {
    margin-top: 10px;
  }

  .promo-card {
    width: auto;
    height: 140px;

    img {
      width: 44px;
      height: 44px;
    }
  }

  .blog-card {
    width: 100%;
    aspect-ratio: 1 / 1;
  }

  .blog-title {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .first-banner__container {
    height: 220px;
    background-position: 90%;

    .first-banner__content {
      padding: 0 14px;
      justify-content: flex-start;
      margin-left: 0;

      .banner-text {
        max-width: 200px;
        font-size: 18px;
      }

      .mw-800 {
        max-width: 300px;
      }

      .mt-custom {
        margin-top: 30px;
      }

      .banner-button {
        font-size: 12px;
        width: 130px;
        height: 30px;
      }
    }
  }

  .second-banner {
    padding: 40px 14px;
  }

  .discover__title {
    font-size: 20px;
  }

  .discover__learn-more {
    font-weight: 500;
  }

  .promo-cards__container {
    padding: 0 14px;
  }

  .promo-cards,
  .blog-cards {
    display: grid;
    gap: 14px;
  }

  .promo-card {
    height: 100px;

    h2 {
      font-size: 14px;
    }
  }

  .blog-title {
    font-size: 18px;
  }

  .dashboard-fab {
    right: 20px;
    bottom: 20px;
  }
}
