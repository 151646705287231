@import 'src/style/default_variables';

.catalog-mobile_frame {
  position: relative;
  width: 100%;
  padding: 0 20px 20px;
  transition: all 0.3s ease-out;
}

.catalog-mobile_categories-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  transition: all 0.5s;

  &.move-left {
    margin-left: -100px;
  }
}

.category-image_frame {
  padding: 10px 0;
  width: calc(50% - 10px);
  transition: all 0.3s ease-out;
}

.category-mobile-title {
  font-size: 24px;
  font-weight: 500;
  position: absolute;
  top: -40px;
  transition: all 0.3s;
  color: #333333;
  text-align: left;
  max-width: 600px;
}

.category-image_container {
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
  height: 243px;
  transition: all 0.3s ease-out;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.category-image_title-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
}

.category-image_title {
  color: $color_white;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
}

@media (max-width: 580px) {
  .category-image_container {
    height: 180px;
  }

  .category-image_title {
    font-size: 16px;
    line-height: 18px;
  }
}

@media (max-width: $breakpoint_s) {
  .catalog-mobile_frame {
    padding: 0 10px 10px;
  }

  .category-image_frame {
    padding: 5px 0;
    width: calc(50% - 5px);
  }

  .category-image_container {
    height: 120px;
  }

  .category-image_title {
    font-size: 14px;
    line-height: 18px;
  }
}
