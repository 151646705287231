@import '../../../../style/default_variables';

.rating__charts {
  width: 100%;
  gap: 6px;

  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    align-items: center;

    gap: 7px;

    cursor: pointer;

    .bar {
      flex: 1;

      .line {
        flex-grow: 1;

        height: 6px;
        border-radius: 3px;
        position: relative;

        background-color: #f0f0f0;

        transition: 0.2s linear;

        .line-fill {
          border-radius: 3px;
          height: 100%;
          position: absolute;

          background-color: $color_accent_red;
        }
      }
    }

    .count {
      flex: 0 0 auto;
      width: 20px;

      font-size: 10px;
      text-align: right;
      font-weight: 700;
      opacity: 0.5;

      transition: 0.2s linear;
    }

    &:hover {
      .count {
        opacity: 0.9;
        scale: 1.2;
      }

      .bar {
        .line {
          background-color: #e8e8e8;
        }
      }
    }
  }
}
