@import '../../../../style/default_variables';

@mixin catalog__root-button(
  $class: 'default',
  $background: $color_tertiary_gray_dark,
  $color: $color_primary_black
) {
  .catalog__root-button_#{$class} {
    width: fit-content;
    height: 40px;

    border-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.25s;

    background: transparent;

    @content;
    @include catalog__root-button_title($color);
  }

  @media (max-width: $breakpoint_m) {
    .catalog__root-button_#{$class} {
      background: #f5f5f5;
      padding: 22px 10px;
    }
  }
}

@media (max-width: $breakpoint_m) {
  .catalog__root-button_container {
    margin-left: 20px;
  }

  .catalog__root-button_container {
    margin-left: 40px;
  }

  .catalog__root-button_title {
    margin-left: 10px;
  }
}

@mixin catalog__root-button_title($color) {
  .catalog__root-button_title {
    color: $color;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
  }
}

@include catalog__root-button;
@include catalog__root-button('active', $color_primary_black, $color_white);

.container__arrow-icon {
  display: none;

  svg {
    width: 20px;
    height: 20px;
  }
}

@media (min-width: $breakpoint_m) {
  .catalog__root-button_title {
    display: none;
  }
}

@media (max-width: $breakpoint_m) {
  .catalog__root-button_title {
    margin-left: 10px;
  }
}

@media (max-width: $breakpoint_m) {
  .catalog__root-button_container {
    order: 1;
    width: 100%;
    margin-left: 0;
  }

  .catalog__root-button_title {
    margin-left: 10px;
  }

  .container__arrow-icon {
    display: block;
  }

  @mixin catalog__root-button_medium($class: 'default') {
    @include catalog__root-button($class) {
      height: 28px;
      padding: 0;
      background: transparent;
    }
  }

  @include catalog__root-button_medium;
  @include catalog__root-button_medium('active');
}
