@import '../../../../style/default_variables';

.reviews__link {
  color: $color_accent_red;

  font-family: 'Noto Sans', sans-serif;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;

  margin-left: 5px;

  &.active {
    text-decoration-line: underline;
  }
}
