@import 'src/style/default_variables';

.post-gallery__frame {
  width: 50%;
  position: relative;
  transition: all 0.3s;
}

.post-gallery__wrapper {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;

  &:hover {
    .button-slide-prev,
    .button-slide-next {
      opacity: 1;
    }
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.post-gallery_counter {
  align-items: center;
  position: absolute;
  bottom: 8px;
  left: 16px;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 6px 8px;
  color: #fff;
  border-radius: 8px;
  z-index: 5;
  font-size: 14px;
  line-height: 20px;
  display: none;
}

.swiper-slide-active {
  opacity: 1;
}

.post-swiper-slide {
  cursor: zoom-in;
}

.swiper-slide {
  width: auto !important;
  height: 310px !important;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.post-image-slide {
  display: block !important;
  overflow: hidden;
  position: relative !important;
  opacity: 1;

  p {
    margin-top: -23px;
  }

  img {
    margin-top: 1px !important;
  }
}

.swiper-slide img {
  display: block;
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
  border-radius: 10px;
  margin: auto;
}

.button-slide-prev,
.button-slide-prev__zoom-swiper,
.button-slide-next,
.button-slide-next__zoom-swiper {
  width: 44px;
  height: 44px;
  border-radius: 8px;
  background: $color_white;
  position: absolute;
  top: 50%;
  margin: -20px 0 0;
  z-index: 5;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-slide-prev,
.button-slide-prev__zoom-swiper {
  transform: rotate(180deg);
  left: 16px;
}

.button-slide-next,
.button-slide-next__zoom-swiper {
  right: 16px;
}

.skeleton-gallery__container {
  width: 48%;
  height: 310px;
  border-radius: 10px;
  overflow: hidden;
}

.descriptor {
  display: block;
  padding: 2px 0;
  font-size: 1rem;
  text-align: center;
  background: #dd2006;
  color: #dedede;
}

.post-gallery__descriptor {
  position: absolute;
  width: 300px;
  top: 32px;
  left: -102px;
  transform: rotate(315deg);
  z-index: 2;
}

@media (max-width: 1420px) {
  .post-gallery__frame,
  .skeleton-gallery__container {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .swiper-slide {
    height: auto !important;
  }

  .post-gallery_counter {
    display: block;
  }

  .descriptor {
    font-size: 0.9rem;
  }

  .post-gallery__descriptor {
    top: 30px;
    left: -106px;
  }
}
