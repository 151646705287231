.locale-currency__container {
  height: 40px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  .locale-currency__settings {
    width: 100%;
    white-space: nowrap;
    font-size: 14px;
    line-height: 19px;
    color: #110b11;
  }
}

.locale-currency__icon {
  svg {
    width: 24px;
    height: 24px;
  }
}

.locale-currency__container--modal {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  max-width: 620px;
  max-height: calc(100vh - 100px);
  padding: 50px 50px 10px 80px;

  overflow: auto;
  border-radius: 10px;

  transition-duration: 0.3s;
  transition-property: background-color;
  transition-timing-function: ease-in-out;

  outline: none;
  background-color: #f5f5f5;

  &::-webkit-scrollbar {
    display: none;
  }
}

.locale-currency__header {
  display: flex;
  align-items: baseline;
  flex-direction: row;
  justify-content: space-between;

  width: 460px;

  .locale-currency__title {

    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 33px;

    color: #110b11;
  }

  .locale-currency__icon__close {
    img {
      cursor: pointer;
    }

    img:hover {
      -webkit-animation-name: spin-180;
      -webkit-animation-duration: 500ms;
    }
  }

  @keyframes spin-180 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }

  .locale-currency__icon__back {
    cursor: pointer;

    img {
      cursor: pointer;
      transition: 0.3s;
      transition-property: all;
    }

    img:hover {
      width: 32px;
      transform: translate(-7px, 0);
    }

    &:hover svg {
      width: 32px;
      transform: translate(-7px, 0);
    }
  }
}

.locale-currency__body {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;

  width: 95%;

  .locale-currency__form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;

    width: calc(100% - 30px);

    .error-message-container {
      z-index: unset;
      transition: unset;
    }

    .input-container {
      margin-top: 35px;
    }
  }
}

@media (max-width: 768px) {
  .locale-currency__container {
    display: none;
  }
}
