@import "../../style/default_variables";

.my-favorite-posts__container {
  min-width: 320px;
  min-height: 798px;
  width: 100%;
  padding: 0 20px;
  margin-top: 40px;
}

.my-favorite-posts {
  max-width: 1420px;
  width: 100%;
  margin: 0 auto;
}

.product-cards-area {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 14px;
  grid-row-gap: 14px;
}

.my-favorite-posts__text-row {
  display: flex;
  align-items: center;
  gap: 20px;
}

.my-favorite-posts__title {
  text-align: left;
  font-size: 28px;
  font-weight: 600;
}

.my-favorite-posts__count {
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  opacity: 0.6;
  color: #110b11;
}

@media (max-width: 1420px) {
  .my-favorite-posts__title {
    font-size: 24px;
  }
}

@media (max-width: 1200px) {
  .product-cards-area {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (max-width: 1000px) {
  .product-cards-area {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 768px) {
  .my-favorite-posts__container {
    margin-top: 20px;
  }

  .product-cards-area {
    margin-top: 10px;
    grid-template-columns: repeat(3, 1fr);
  }

  .my-favorite-posts__title {
    font-size: 20px;
  }

  .my-favorite-posts__count {
    font-size: 14px;
    line-height: 20px;
  }
}

@media (max-width: 480px) {
  .product-cards-area {
    margin-top: 10px;
    grid-template-columns: repeat(2, 1fr);
  }

  .my-favorite-posts__container {
    padding: 0 14px;
  }

  .my-favorite-posts__title {
    font-size: 18px;
  }
}
