@import 'src/style/default_variables';

/*
 * SCSS Configuration for `DefaultButton`
 */

%button-base {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  width: 100%;
  height: 60px;

  font-weight: 700;
  font-size: 18px;
  line-height: 25px;

  border-radius: 10px;
  cursor: pointer;
  border: 2px solid transparent;
  transition: background-color 0.2s linear, border-color 0.2s linear, color 0.05s linear;
}

.default__component-button {
  @extend %button-base;

  &.static-accent,
  &.hover-accent:hover,
  &.disabled-accent.disabled,
  &.loader-accent.loading,
  &.toggle-accent.toggle {
    background-color: $color_accent_red;
    color: $color_white;
    border-color: $color_accent_red;
  }

  &.static-gray,
  &.hover-gray:hover,
  &.disabled-gray.disabled,
  &.loader-gray.loading,
  &.toggle-gray.toggle {
    background-color: $color_light_gray;
    color: $color_white;
    border-color: $color_light_gray;
  }

  &.static-black,
  &.hover-black:hover,
  &.disabled-black.disabled,
  &.loader-black.loading,
  &.toggle-black.toggle {
    background-color: $color_primary_black;
    color: $color_white;
    border-color: $color_primary_black;
  }

  &.static-outline,
  &.hover-outline:hover,
  &.disabled-outline.disabled,
  &.loader-outline.loading,
  &.toggle-outline.toggle {
    background-color: transparent;
    color: $color_accent_red;
    border-color: $color_accent_red;
  }

  &.static-gray-outline,
  &.hover-gray-outline:hover,
  &.disabled-gray-outline.disabled,
  &.loader-gray-outline.loading,
  &.toggle-gray-outline.toggle {
    background-color: transparent;
    color: $color_light_gray;
    border-color: $color_light_gray;
  }

  &.static-black-outline,
  &.hover-black-outline:hover,
  &.disabled-black-outline.disabled,
  &.loader-black-outline.loading,
  &.toggle-black-outline.toggle {
    background-color: transparent;
    color: $color_primary_black;
    border-color: $color_primary_black;
  }

  &.disabled {
    user-select: none;
    pointer-events: none;
  }

  &.loading {
    pointer-events: none;
  }
}

.button__size-style- {
  &--default {
    height: 40px;

    @media (min-width: $breakpoint_m) {
      height: 35px;
    }
  }

  &--profile-subscription {
    height: 35px;
    max-width: 200px;
    min-width: 180px;

    font-weight: 600;
    font-size: 14px;

    @media (max-width: $breakpoint_s) {
      max-width: 260px;
    }
  }

  &--cancel-responsive {
    height: 50px;

    @media (max-width: $breakpoint_m) {
      height: 45px;
    }
  }

  &--submit-responsive {
    height: 50px;

    @media (max-width: $breakpoint_m) {
      height: 45px;
    }
  }

  &--view-review {
    font-size: 14px;

    height: 40px;
  }

  &--add-review {
    font-size: 14px;

    height: 40px;
  }
}
