@import 'src/style/default_variables';

.creation-complete-container {
  min-height: 658px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;

  p {
    text-align: center;
    font-size: 28px;
    line-height: 130%;
    color: #110b11;
  }
}

.creation-complete_button-container {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 40px;
}

.button-creation-page {
  max-width: 220px;
  width: 100%;
  height: 60px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
}

.to-main-page {
  background: transparent;
  border: 2px solid $color_accent_red;
  color: $color_accent_red;

  &:hover {
    border: 2px solid $color_primary_black;
    color: $color_primary_black;
  }
}

.to-another-ad {
  background: $color_accent_red;
  border: 2px solid $color_accent_red;
  color: $color_background;

  &:hover {
    background: $color_primary_black;
    border: 2px solid $color_primary_black;
    color: $color_background;
  }
}

@media (max-width: 598px) {
  .creation-complete_button-container {
    gap: 20px;
    flex-direction: column;
    align-items: center;
  }
}
