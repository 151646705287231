@import 'src/style/default_variables';

.button-save_frame {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: end;
}

.button-save_container {
  width: 100%;
  max-width: 460px;
  height: 60px;
  display: flex;
  justify-content: end;
  transition: all 0.3s;
}

.button-save {
  width: 100%;
  max-width: 140px;
  margin-left: auto;
  padding: 10px 20px;
  border-radius: 10px;
  background: rgba(17, 11, 17, 0.2);
  color: $color_white;

  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  cursor: text;
  transition: all 0.3s;

  $mouse-device: '(hover: hover) and (pointer: fine)';

  &.active {
    &:hover {
      @media #{$mouse-device} {
        background: $color_primary_black;
      }
    }

    cursor: pointer;
    background: $color_accent_red;
  }
}

@media (max-width: 1060px) {
  .button-save_container {
    margin-right: auto;
    margin-left: auto;
  }
}
