@import 'src/style/default_variables';

.no-posts__container {
  margin: 20px auto 0;
  text-align: center;

  .no-my-posts-text, .favorites-no-posts-text {
    max-width: 380px;
    margin: 10px auto 20px auto;
    opacity: 1;
    font-size: 20px;
  }

  .favorites-no-posts-text {
    margin: 20px auto;
  }

  .favorites-blank-slate {
    margin-top: 100px;
  }

  p {
    font-size: 16px;
    line-height: 130%;
    color: $color_secondary_gray;
    opacity: 0.6;
  }

  .bold {
    opacity: 1;
    font-size: 32px;
  }

  img {
    max-width: 400px;
    max-height: 390px;
  }

  &.no-posts-found {
    grid-column: 1 / -1;
  }
}

.no-posts__button {
  margin-top: 10px;

  color: $color_white;
  border: 2px solid $color_accent_red;
  background: $color_accent_red;

  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  padding: 8px 20px;
  border-radius: 10px;
  transition: all 0.3s;

  &:hover {
    background: $color_primary_black;
    border: 2px solid $color_primary_black;
  }
}

@media (max-width: $breakpoint_m) {
  .no-posts__container  {
    p, .no-my-posts-text, .favorites-no-posts-text {
      font-size: 16px;
      line-height: 22px;
    }

    .favorites-blank-slate {
      margin-top: 50px;
    }

    img {
      max-width: 310px;
      max-height: 300px;
    }
  }
}

@media (max-width: $breakpoint_s) {
  .no-posts__button {
    font-size: 14px;
    line-height: 19px;
  }
}
