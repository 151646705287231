@import '../../../../style/default_variables';

.images-bar__container {
  display: flex;

  padding: 20px 20px 10px 20px;
  gap: 20px;

  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .images-bar__image-container {
    max-width: 70px;
    max-height: 70px;

    position: relative;

    img {
      max-width: 70px;
      max-height: 70px;
      width: 70px;
      height: 70px;

      border: 2px solid $color_primary_black;
      border-radius: 5px;
    }

    .cross-button {
      position: absolute;
      top: -8px;
      right: -8px;

      width: 16px;
      height: 16px;

      display: flex;
      justify-content: center;
      align-items: center;

      background-color: $color_background;

      cursor: pointer;
    }
  }
}
