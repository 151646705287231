@import '../../../style/default_variables';

.burger-button {
  width: 18px;
  height: 20px;
  transition: all 0.3s;
}

.span-line {
  width: 100%;
  height: 2.1px;
  margin: 4px 0;
  display: block;
  position: relative;
}

@mixin config-active-span($prefix, $background, $animations...) {
  .#{$prefix} {
    .span-line {
      background: $background;
    }
  }

  @each $i in $animations {
    .#{$prefix} span:nth-child(#{nth($i, 1)}) {
      animation: ease 0.7s #{nth($i, 2)} forwards;
    }
  }
}

@include config-active-span('active', $color_primary_black, '1' top, '2' scaled, '3' bottom);

@include config-active-span(
  'not-active',
  $color_primary_black,
  '1' top-2,
  '2' scaled-2,
  '3' bottom-2
);

@media (max-width: $breakpoint_m) {
  @include config-active-span('active', $color_primary_black);
}

@keyframes top {
  0% {
    top: 0;
    transform: rotate(0);
  }
  50% {
    top: 6px;
    transform: rotate(0);
  }
  100% {
    top: 6px;
    transform: rotate(45deg);
  }
}

@keyframes top-2 {
  0% {
    top: 6px;
    transform: rotate(45deg);
  }
  50% {
    top: 6px;
    transform: rotate(0deg);
  }
  100% {
    top: 0;
    transform: rotate(0deg);
  }
}

@keyframes bottom {
  0% {
    bottom: 0;
    transform: rotate(0);
  }
  50% {
    bottom: 6px;
    transform: rotate(0);
  }
  100% {
    bottom: 6px;
    transform: rotate(135deg);
  }
}

@keyframes bottom-2 {
  0% {
    bottom: 6px;
    transform: rotate(135deg);
  }
  50% {
    bottom: 6px;
    transform: rotate(0);
  }
  100% {
    bottom: 0;
    transform: rotate(0);
  }
}

@keyframes scaled {
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes scaled-2 {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
