@import 'src/style/default_variables';

.categories-list_title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
  color: $color_primary_black;
}

.categories-list {
  transition: all 0.3s;
  padding-left: 14px;
}

.categories-list_item {
  font-size: 16px;
  line-height: 22px;
  color: $color_primary_black;
  cursor: pointer;
  transition: all 0.3s;
  opacity: 0.8;
  display: block;

  &.title {
    opacity: 1;
  }

  &.active {
    opacity: 1;
    color: $color_accent_red;
  }

  &:hover {
    color: $color_accent_red;
  }
}
