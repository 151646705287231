@import 'src/style/default_variables';

.third-step {
  min-height: 492px;
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  transition: all 0.2s;
}

.third-step-content {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 0 auto;
  box-sizing: border-box;
}

.third-step-card {
  max-width: 460px;
  height: 430px;
  width: 100%;

  padding: 20px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;

  background: $color_white;
  box-sizing: border-box;

  &.hide-phone {
    p {
      opacity: 0.1;
    }
  }

  & p {
    margin: 0 auto;
    text-align: center;
    color: $color_primary_black;
    font-weight: 300;
    font-size: 24px;
    line-height: 33px;
    opacity: 0.8;
    transition: all 0.3s;
  }

  & p:first-child {
    opacity: 1;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
  }
}

.button-submit_container {
  width: 100%;
  display: flex;
}

.button-submit-product {
  max-width: 220px;
  width: 100%;
  height: 60px;
  background: $color_accent_red;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: $color_white;
  border: $color_accent_red;
}

.google-maps-container {
  width: 100%;
  height: 320px;
  margin: 0 auto;
}

.without-location-info-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  margin: auto auto;

  a {
    text-align: center;
    color: $color_primary_black;
    font-weight: 300;
    font-size: 24px;
    line-height: 33px;
    opacity: 0.8;
    margin: 0 auto;
    text-decoration: none;
  }
}

@media (max-width: 1000px) {
  .third-step-content {
    align-items: center;
    flex-direction: column;
  }
}

@media (max-width: $breakpoint_s) {
  .button-submit_container {
    flex-wrap: wrap;
    justify-content: center;
  }
}
