.avatar-container {
  box-sizing: border-box;
  width: 180px;
  height: 180px;
  background: #F2F2F2;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 1000px;
  transition: all 0.3s;
  overflow: hidden;
  z-index: 2;

  .gamar-loader-box {
    margin-top: 10px;
  }

  &.loading {
    .upload-avatar-buttons-container, .avatar-overlay {
      opacity: 1;
      visibility: visible;
    }

    img {
      opacity: 0;
    }
  }

  &:hover {
    .upload-avatar-buttons-container, .avatar-overlay {
      opacity: 1;
      visibility: visible;
    }
  }

  &.none-avatar {
    &:hover {
      .avatar-overlay {
        transition: all .3s;
        background: linear-gradient(0deg, rgba(244, 68, 46, 0.2), rgba(244, 68, 46, 0.2)), #F2F2F2;
      }
    }
  }
}

.upload-avatar-buttons-container {
  opacity: 0;
  visibility: hidden;
  height: 24px;
  display: flex;
  gap: 30px;
  transition: all .3s;
  z-index: 2;
}

.edit-icon-btn, .delete-icon-btn {
  position: relative;
  border-radius: 10px;
  transition: all .3s;
  cursor: pointer;

  .default-icon {
    transition: all .3s;
    opacity: 1;
  }

  .hover-icon {
    margin-top: -24px;
    transition: all .3s;
    opacity: 0;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    background: #FFFFFF;

    .default-icon {
      opacity: 0;
    }

    .hover-icon {
      opacity: 1;
    }
  }
}

.avatar-content {
  position: absolute;
  z-index: 0;

  img {
    width: 180px;
    height: 180px;
    border-radius: 1000px;
    object-position: center;
    object-fit: cover;
  }

  h1 {
    font-size: 80px;
  }
}

.upload-avatar-back-arrow {
  position: relative;

  svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
}

.avatar-frame {
  background: red;
  z-index: 10;
}


.avatar-overlay {
  background: rgba(242, 242, 242, 0.9);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all .3s;

  &.without-avatar {
    cursor: pointer;
  }
}
