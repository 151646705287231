@import 'src/style/default_variables';

.my-purchases-frame {
  max-width: 1460px;
  min-width: 320px;
  min-height: 798px;
  width: 100%;
  margin: 40px auto 0;
  padding: 0 20px;
  font-family: 'Noto Sans', sans-serif;

  button {
    font-family: 'Noto Sans', sans-serif;
  }
}

.my-purchases-header {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .items-count {
    opacity: 0.7;
    margin-left: 20px;
  }
}

.my-purchases-tools {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  transition: margin .2s;

  .chips button {
    padding: 8px 20px;
    border-radius: 10px;
    border: 1px solid $color_primary_black;
    background: transparent;
    transition: all 0.2s;

    font-weight: bold;
    font-size: 14px;


    &:hover, &.active {
      color: $color_accent_red;
      border-color: $color_accent_red;
    }

    &:last-child {
      margin-left: 20px;
    }
  }
}

.sorting-tools {
  display: flex;
  align-items: center;
  gap: 20px;
}

.my-purchases {
  margin-top: 30px;
  width: 100%;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  grid-auto-rows: minmax(376px, auto);
  grid-gap: 1rem;
}

.my-purchases-blank-slate {
  margin: 60px auto;
  width: fit-content;

  img {
    max-width: 320px;
    width: 100%;
    max-height: 306px;
    height: 100%;
  }

  p {
    font-size: 20px;
    text-align: center;
  }
}

@media (max-width: $breakpoint_m) {
  .my-purchases-tools {
    margin-top: 20px;

    .chips button {
      padding: 8px 10px;
      font-size: 12px;

      &:last-child {
        margin-left: 10px;
      }
    }
  }

  .my-purchases {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }
}
