@import 'src/style/default_variables';

.modal_frame {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  background: rgba(17, 11, 17, 0.5);
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;
  display: none;
  justify-content: center;
  align-items: center;

  &.show {
    display: flex;
    opacity: 1;
    visibility: visible;
  }
}

.modal_container {
  width: 600px;
  padding: 50px 80px;
  background: $color_tertiary_gray_dark;
  border-radius: 10px;
  transition: all 0.2s;
  z-index: -1;
  position: relative;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.modal_title {
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  color: #110b11;
}

.modal_close-button {
  position: absolute;
  right: 56px;
  top: 62px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: all 0.2s;

  $mouse-device: '(hover: hover) and (pointer: fine)';

  svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    @media #{$mouse-device} {
      -webkit-animation-name: spin-180;
      -webkit-animation-duration: 500ms;
    }
  }
}

@media (max-width: $breakpoint_m), (max-height: $breakpoint_m) {
  .modal_container {
    border-radius: 0;
    padding: 20px;
    width: 100%;
    height: 100%;
  }

  .modal_title {
    font-size: 22px;
    line-height: 34px;
  }

  .modal_close-button {
    top: 20px;
    right: 20px;
  }
}
