@import 'src/style/default_variables';

.user-posts__category-chip {
  padding: 7px 20px;
  border-radius: 10px;

  border: 1px solid $color_primary_black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  cursor: pointer;

  span {
    color: $color_primary_black;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    white-space: nowrap;
  }

  &.selected {
    border-color: $color_accent_red;

    span {
      color: $color_accent_red;
    }

    :last-child {
      margin-right: -5px;
    }
  }
}

.row-right-button {
  right: 0;

  width: 30px;
  height: 30px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.row-left-button {
  left: 0;

  width: 30px;
  height: 30px;
  rotate: 180deg;

  display: flex;
  justify-content: center;
  align-items: center;
}

.user-posts__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  min-height: 600px;
  min-width: 320px;

  .categories-row {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 20px;

    .clear-all-btn {
      min-width: auto;

      text-decoration: underline;
      white-space: nowrap;

      color: #110b11;

      font-size: 16px;
      cursor: pointer;
    }

    .user-posts__categories-row-container {
      position: relative;
      display: flex;
      width: auto;
      overflow-x: auto;
      gap: 20px;
      scroll-behavior: smooth;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      .arrow-btn {
        height: 35px;
        width: 116px;

        top: 0;

        display: flex;
        align-items: center;
        justify-content: end;
        position: absolute;

        background: linear-gradient(270deg, #f5f5f5 0%, rgba(249, 249, 249, 0) 100%);
        cursor: pointer;
        transition: all 0.3s;
      }

      .user-posts__categories-row {
        display: flex;
        width: auto;
        overflow-x: auto;
        gap: 20px;
        scroll-behavior: smooth;

        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }
      }
    }
  }

  .user-posts__posts-container {
    display: flex;
    gap: 20px 20px;
    flex-wrap: wrap;
  }
}

@media (max-width: $breakpoint_l) {
  .user-posts__container {
    max-width: none;
  }
}

@media (max-width: $breakpoint_m) {
  .user-posts__container .user-posts__posts-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    div {
      min-width: auto;
    }
  }
}

@media (max-width: $breakpoint_s) {
  .user-posts__container {
    .categories-row .user-posts__categories-row-container .arrow-btn {
      width: auto;
    }

    .user-posts__posts-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
