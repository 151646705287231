@import 'src/style/default_variables';

.catalog-mobile__drop-content {
  position: absolute;
  overflow: hidden;
  border-radius: 0 0 10px 10px;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  transform: translate(101%);
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: $color_background;

  transform: translate(0);
  display: block;
  z-index: 5;

  &.show {
    transform: translate(-100%);
  }
}

.catalog-mobile__close-drop-button {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 10px;
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate(0);
  background: transparent;
  border-bottom: 1px solid $color_tertiary_gray_dark;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  cursor: pointer;

  div {
    display: flex;
    align-items: center;
  }

  &.move-action {
    transform: translate(-30px);
  }

  span {
    margin-left: 5px;
  }

  svg {
    width: 20px;
    height: 20px;
    transform: rotate(180deg);
  }
}

.post-count {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin-left: auto !important;
  color: $color_accent_red;
}

.catalog-mobile__drop-list {
  margin-top: 10px;
  padding: 0 10px;
  list-style: none;
}

.catalog-mobile__drop-item {
  width: 100%;
  margin-bottom: 10px;

  a {
    color: $color_primary_black;
  }
}

.catalog-mobile__drop-button {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate(0);
  background: transparent;
  border-bottom: 1px solid $color_tertiary_gray_dark;

  &.move-action {
    transform: translate(-30px);
  }
}
