.inputs-containers {
  min-height: 432px;
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 260px;
}

.select-frame-column {
  max-width: 460px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (max-width: 1200px) {
  .inputs-containers {
    align-items: center;
    flex-direction: column;
    gap: 40px;
  }
}
