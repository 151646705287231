@import 'src/style/default_variables';

.thumbs__wrapper {
  margin-top: 16px;
  position: relative;
}

.swiper-slide.thumbs__swiper-slide {
  opacity: 1;
}

.thumbs__swiper-slide {
  width: 100px !important;
  height: 70px !important;
  border-radius: 10px;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
  transition: all 0.3s;
  border: 1px solid transparent;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.swiper-slide-thumb-active {
  opacity: 0.3 !important;
  border: 1px solid $color_primary_black;
}

@media (max-width: $breakpoint_m) {
  .thumbs__wrapper {
    display: none;
  }
}
