@import '../../../../../style/default_variables';

.filter-sort-container {
  position: absolute;
  right: 0;
  bottom: -10px;
  width: fit-content;
  display: flex;
  justify-content: end;
  align-items: center;
  justify-self: end;
  gap: 10px;
  grid-area: 2 / 2 / 3 / 3;
}

.sort-by-text {
  white-space: nowrap;
  font-size: 16px;
  line-height: 22px;
}

.sorting-menu-container,
.mobile-filter-button {
  display: flex;
  align-items: center;
  background: $color_white;
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;

  p {
    font-size: 16px;
  }
}

.mobile-filter-button {
  display: none;
}

@media (max-width: 1000px) {
  .filter-sort-container {
    gap: 5px;
  }

  .mobile-filter-button {
    display: block;
  }

  .sorting-menu-container {
    .Icon {
      width: 20px;
    }
  }

  .sort-by-text,
  .sorting-menu-container p {
    display: none;
  }

  svg {
    width: 24px;
    height: 24px;
    margin: 0 !important;
  }

  .sorting-menu-container,
  .mobile-filter-button {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 38px;
    max-width: 38px;
  }
}
