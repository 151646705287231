@import 'src/style/default_variables';

.static-page__frame {
  max-width: 1420px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 130px 20px 0;

  .static-page__title-container {
    max-width: 1420px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      transition: all 0.3s;
      font-size: 40px;
      font-weight: 700;
      text-align: center;
    }
  }

  .hr {
    margin: 20px auto;
    max-width: 1420px;
    width: 80%;
    opacity: 0;
    transition: all 0.3s;
  }

  .hr-inner {
    margin: 10px auto;
    max-width: 1420px;
    width: 80%;
    opacity: 0;
    transition: all 0.3s;
  }

  .divider {
    margin: 50px auto;
    max-width: 1420px;
    width: 90%;
    border: 1px solid $color_accent_red;
    transition: all 0.3s;
  }

  .static-page__content {
    max-width: 1420px;
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 20px;
    transition: all 0.3s;

    .contact_block {
      display: flex;
      align-items: center;

      svg {
        margin-right: 5px;
      }

      a {
        margin: 0 5px;
        color: #e3262f;
        text-decoration: none;
      }
    }

    a {
      color: #e3262f;
      text-decoration: none;
    }

    .list-item {
      display: list-item;
      list-style-type: circle;
      margin: 0;
      padding-left: 20px;
    }

    .list-item::marker {
      color: black !important;
    }

    .list-item::first-line {
      all: unset;
    }

    li {
      text-indent: 20px;
      list-style-type: none;
      white-space: pre-line;
    }

    li::first-line {
      font-weight: bold;
    }
  }
}

.social-icons {
  display: flex;
  gap: 30px;

  svg {
    width: 48px;
    height: 48px;
  }
}

@media (max-width: 768px) {
  .social-icons {
    svg {
      width: 36px;
      height: 36px;
    }
  }
}

@media (max-width: $breakpoint_m) {
  .static-page__frame {
    .static-page__title-container {
      h1 {
        font-size: 26px;
      }
    }

    .divider {
      margin: 30px auto;
    }

    .static-page__content {
      font-size: 18px;
    }
  }
}

@media (max-width: $breakpoint_s) {
  .static-page__frame {
    .hr {
      margin: 10px auto;
    }
  }
}
