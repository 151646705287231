@import 'src/style/default_variables';

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.authorization__container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  max-width: 620px;
  max-height: calc(100vh - 100px);
  padding: 50px 50px 50px 80px;

  overflow: auto;
  border-radius: 10px;

  transition-duration: 0.3s;
  transition-property: background-color;
  transition-timing-function: ease-in-out;

  outline: none;
  background-color: #f5f5f5;

  &::-webkit-scrollbar {
    display: none;
  }
}

.authorization__header {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  width: 460px;

  .authorization__title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;

    color: #110b11;
  }

  .authorization__icon__close {
    img {
      cursor: pointer;
    }

    $mouse-device: '(hover: hover) and (pointer: fine)';

    img:hover {
      @media #{$mouse-device} {
        -webkit-animation-name: spin-180;
        -webkit-animation-duration: 500ms;
      }
    }
  }

  @keyframes spin-180 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }

  .authorization__icon__back {
    cursor: pointer;

    img {
      cursor: pointer;
      transition: 0.3s;
      transition-property: all;
    }

    img:hover {
      width: 32px;
      transform: translate(-7px, 0);
    }

    &:hover svg {
      width: 32px;
      transform: translate(-7px, 0);
    }
  }
}

.authorization__body {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;

  width: 100%;

  .authorization__form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;

    width: calc(100% - 30px);

    .error-message-container {
      z-index: unset;
      transition: unset;
    }

    .input-container {
      margin-top: 35px;
    }
  }
}

.authorization__footer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;

  width: 100%;
  margin-bottom: 10px;

  .authorization__button {
    margin-top: 50px;
    width: 100%;
    height: 60px;
    font-size: 18px;
    line-height: 25px;
  }

  .authorization__move {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: 100%;
    margin-top: 20px;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;

    opacity: 0.8;
    color: #110b11;
  }
}

.authorization__google-login-button {
  background: transparent;
  max-width: 40px;
  max-height: 40px;
  padding: 0;
  margin-left: 20px;

  svg {
    width: 40px;
    height: 40px;
  }
}

.authorization__container.small {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  padding: 50px 25px 50px 25px !important;

  .authorization__header {
    width: 540px;
  }

  .authorization__body {
    align-items: center;

    width: calc(100% - 50px);

    .authorization__form {
      width: calc(100% - 50px);
    }
  }

  .authorization__icon__back {
    margin-right: 20px;
  }

  .authorization__title {
    text-align: left;

    width: inherit;
  }
}

.authorization__button {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 60px;

  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;

  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s;

  background: $color_accent_red;
  color: $color_white;

  &:hover {
    background: $color_primary_black;
  }
}

.authorization__button--disabled {
  cursor: unset;
  background: rgba(17, 11, 17, 0.2) !important;
  color: #ffffff;

  &:hover {
    box-shadow: none;
    background: rgba(17, 11, 17, 0.2) !important;
  }
}

.authorization__button--disabled:hover {
  animation: shake 0.6s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  cursor: default;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(1px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(2px, 0, 0);
  }
}

.authorization__small-text {
  text-align: start;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;

  width: fit-content;

  color: #110b11;
  opacity: 0.8;
}

.authorization__small-text--error {
  color: #dd2006;

  margin: auto auto 5px;
}

.hidden {
  opacity: 0;
}

.authorization__social {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;

  width: 100%;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;

  color: #110b11;
  opacity: 0.8;

  svg {
    width: 40px;
    height: 40px;
  }

  button {
    width: fit-content;
    margin-left: 20px;
    background: transparent;
  }
}

.underline {
  cursor: pointer;
  text-decoration-line: underline;
  transition: 0.1s;

  &:hover {
    color: #dd2006;
  }
}

.mt-5 {
  margin-top: 5px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-80 {
  margin-top: 80px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-40 {
  margin-bottom: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-30 {
  padding-bottom: 30px;
}

.colored {
  color: #dd2006;
}

.space-between {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 700px) {
  .authorization__container {
    padding: 25px;
    max-width: unset;
    max-height: unset;
    width: 100%;
    height: 100%;

    border-radius: 0;

    .authorization__header {
      padding-top: 25px;
      width: 100%;
    }

    .authorization__body {
      align-items: center;

      .authorization__form {
        width: 100%;

        .input-container {
          max-width: 100%;
        }
      }
    }
  }

  .authorization__container.small {
    display: flex;

    padding: 15px !important;
    max-width: unset;
    max-height: unset;
    width: 100%;
    height: 100%;

    .authorization__header {
      padding-top: 25px;
      width: 100%;

      .authorization__title {
        font-size: 23px;
      }

      .authorization__icon__close {
        margin-right: 10px;
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }

    .authorization__body {
      align-items: center;
      width: 100%;

      .authorization__form {
        width: 100%;

        .input-container {
          max-width: 100%;
        }
      }
    }
  }
}

@keyframes shake-not-allowed {
  0% {
    transform: translate(-8px, -14px);
  }
  33% {
    transform: translate(-10px, -14px);
  }
  66% {
    transform: translate(-10px, -16px);
  }
  100% {
    transform: translate(-8px, -16px);
  }
}
