.header__container {
  min-width: 320px;
  width: 100%;
  padding: 20px;

  .header {
    width: 100%;
    height: 60px;
    max-width: 1420px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
    transition: all 0.2s;
  }
}

.header__promo-line {
  height: 50px;
  padding: 13px 20px 12px 1px;

  color: #fff;
  text-align: center;
  font-size: 16px;
  background: #3c3c3b;
  cursor: pointer;

  display: flex;

  .promo-line__text {
    margin: auto;
  }

  .promo-line__icon {
    color: #fff;
    position: absolute;
    right: 5px;
    top: 7%;
  }
}

.header__logo {
  cursor: pointer;
  width: 170px;
  height: 60px;
}

@media (max-width: 1420px) {
  .header {
    height: 40px;
  }

  .header__logo {
    width: 120px;
    height: 40px;

    img {
      width: 120px;
      height: 40px;
    }
  }

  .header__promo-line {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .header__promo-line {
    height: 50px;
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .header__container {
    padding: 14px;
  }

  .header {
    gap: 10px;
  }
}
