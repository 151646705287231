.filter-header__container {
  margin-bottom: 40px;
  position: relative;
}

@media (max-width: 1200px) {
  .filter-header__container {
    margin-bottom: 16px;
  }
}
