@import "src/style/default_variables";

.post-location {
  font-size: 16px;
}

.empty-block {
  height: 22px;
}

@media (max-width: 1420px) {
  .post-location {
    font-weight: 300;
  }

  .empty-block {
    display: none;
  }
}

@media (max-width: 768px) {
  .post-location {
    font-size: 14px;
  }
}
