@import '../../../../style/default_variables';

.catalog-navigation-drop-wrap {
  width: 77.5%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  max-height: 848px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: $color_white;
    border-radius: 50px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(17, 11, 17, 0.8);
  }
}

.catalog-navigation-drop {
  left: 0;
  right: 0;
  top: 0;
  padding: 20px 0;
  max-height: 100%;
  visibility: visible;
  opacity: 1;
  position: relative;

  a {
    color: $color_primary_black;
  }
}

.catalog-navigation-head {
  width: fit-content;
  padding: 0 20px;
  margin-bottom: 40px;
  cursor: pointer;

  a {
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 0;
    transition: all 0.25s;

    &:hover {
      color: $color_accent_red;
    }
  }

  a::after {
    content: attr(data-count);
  }

  a::after {
    position: relative;
    margin-left: 20px;
    color: $color_primary_black;
    opacity: 0.8;
    vertical-align: baseline;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    font-feature-settings: 'zero' on;
  }
}

.catalog-navigation-close {
  background-color: transparent;
  position: absolute;
  right: 25px;
  top: 20px;
  padding: 10px;
  height: 40px;
  cursor: pointer;
}

.catalog-navigation-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  padding: 0 20px;
  transition: all 0.3s;
}

@media (max-width: 1420px) {
  .catalog-navigation-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: $breakpoint_m) {
  .catalog-navigation-close {
    opacity: 1;
    color: #110b11;
    top: -75px;
    right: 20px;
    display: flex;
    width: calc(100% - 40px);
    height: 60px;
    border-radius: 15px;
    align-items: center;
    justify-content: space-between;

    .catalog-mobile_categories-button_title {
      color: #110b11;
      font-weight: bold;
      font-size: 20px;
    }
  }
}

@media (max-width: $breakpoint_s) {
  .catalog-navigation-close {
    top: -65px;
    right: 10px;
    width: calc(100% - 20px);
    height: 60px;
    border-radius: 15px;

    .catalog-mobile_categories-button_title {
      color: #110b11;
      font-weight: bold;
      font-size: 20px;
    }
  }
}
