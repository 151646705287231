.blog-feed-card {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  cursor: pointer;
  text-decoration: none;
  background-size: cover;
  background-position: center;
  position: relative;

  .blog-feed-card-image {
    object-fit: cover;
    object-position: center;
    height: 300px;
    border-radius: 20px;
  }
}

.blog-feed-card-content {
  padding: 16px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
}

.blog-feed-card-title {
  font-size: 18px;
  margin: 0 0 8px;
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 1;
  color: #1c1c1c;
}

.blog-feed-card-date {
  font-size: 0.9rem;
  margin: 0 0 8px;
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 1;
  color: #1c1c1c;
}

.blog-feed-card-description {
  font-size: 14px;
  margin: 0 0 16px;
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 1;
  color: #1c1c1c;
}
