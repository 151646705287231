.skeleton-post-avatar-container, .skeleton-call-btns-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.skeleton-post-images-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.skeleton-user-interaction-container {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 10px;

}

@media (max-width < 500px) {
  .skeleton-user-interaction-container {
    justify-content: flex-start;
  }
}
