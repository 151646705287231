@import '../../../../../style/default_variables';

.filter-breadcrumbs-wrapper {
  width: fit-content;
  display: grid;
  font-size: 14px;
}

.breadcrumb-item,
.breadcrumb-separator {
  width: fit-content;
  white-space: pre-wrap;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: $color_primary_black;
  opacity: 0.5;
  cursor: pointer;
  grid-row: 1;
}

.current {
  font-size: 32px;
  line-height: 44px;
  font-weight: bold;
  grid-row: 2;
  grid-column: 1 / span 4;
}

.last {
  display: block;
  opacity: 1;
}

.breadcrumb-separator {
  margin: 0 6px;
}

.products-count {
  opacity: 0.6;
  font-size: 14px;
  margin-top: 8px;
}

@media (max-width: 1200px) {
  .breadcrumb-item,
  .breadcrumb-separator {
    font-size: 18px;
  }

  .current {
    line-height: 100%;
    font-size: 24px;
  }

  .products-count {
    margin-top: 10px;
  }
}

@media (max-width: 768px) {
  .breadcrumb-item,
  .breadcrumb-separator {
    font-size: 16px;
  }

  .current {
    font-size: 20px;
  }

  .last {
    max-width: 294px;
  }
}

@media (max-width: 480px) {
  .breadcrumb-item,
  .breadcrumb-separator {
    font-size: 14px;
  }

  .current {
    font-size: 18px;
  }
}
