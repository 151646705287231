.like-icon_frame {
  z-index: 2;
  position: absolute;
  width: 44px;
  height: 44px;
  border-bottom-left-radius: 10px;
  top: 0;
  right: 0;
  transition: all 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;

  &.liked {
    opacity: 1;
  }
}

.like-icon,
.unlike-icon,
.skeleton-like-icon-frame {
  cursor: pointer;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;

  svg {
    width: 24px;
    height: 24px;
  }

  &.show {
    opacity: 1;
    visibility: visible;
  }
}
