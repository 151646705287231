@import '../../../style/default_variables';

.breadcrumbs_frame {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.breadcrumbs_category-container {
  display: flex;
  gap: 10px;
}

.breadcrumbs_category-name,
.breadcrumbs_symbol,
.breadcrumbs_bold {
  white-space: nowrap;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: $color_primary_black;
}

.breadcrumbs_category-name {
  cursor: pointer;

  &.bold {
    opacity: 1;
  }
}

.breadcrumbs_category-name,
.breadcrumbs_symbol {
  opacity: 0.5;
}

@media (max-width: 1420px) {
  .breadcrumbs_category-name,
  .breadcrumbs_symbol,
  .breadcrumbs_bold {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .breadcrumbs_frame,
  .breadcrumbs_category-container {
    gap: 5px;
  }

  .breadcrumbs_category-name,
  .breadcrumbs_symbol,
  .breadcrumbs_bold {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .breadcrumbs_category-name,
  .breadcrumbs_symbol,
  .breadcrumbs_bold {
    font-size: 14px;
  }
}
