.error-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(70vh);
}

.error-container_frame {
  border-radius: 20px;
  background: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 20px;
}

.redirect-container {
  font-size: 18px;
  text-decoration: none;
  color: #FFFFFF;

  div {
    padding: 10px;
    background: #DD2006;
    max-width: 240px;
    border-radius: 10px;
    cursor: pointer;
  }
}
