@import '../../../../../style/default_variables';

.catalog-navigation-category {
  -ms-flex-preferred-size: 330px;
  flex-basis: 330px;
  margin-right: 20px;
}

.catalog-navigation-category__title {
  width: fit-content;
  cursor: pointer;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 10px;
  transition: all 0.25s;

  &:hover {
    color: $color_accent_red;
  }
}

.catalog-navigation-category__list {
  padding: 0;
  margin-bottom: 30px;
  list-style: none;
}

.catalog-navigation-category__item {
  opacity: 0.7;
  padding: 3px 0px;
  border-radius: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  transition: all 0.25s;

  &:hover {
    background: $color_tertiary_gray_dark;
    opacity: 1;
  }
}

.catalog-inner {
  height: fit-content;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s;

  &.show {
    max-height: 1000px;
  }
}

.show-all {
  width: fit-content;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-decoration-line: underline;
  color: $color_accent_red;
  opacity: 0.8;
  transition: all 0.25s;
}

@media (max-width: $breakpoint_xl) {
  .catalog-navigation-category {
    -ms-flex-preferred-size: 300px;
    flex-basis: 300px;
  }
}
