@import '../../../style/default_variables';

.sort-menu__anchor {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  color: $color_primary_black;
  padding: 10px;
  background: $color_white;
  border: 1px solid $color_tertiary_gray;
  border-radius: 10px;

  &.active {
    color: $color_accent_red;
  }

  svg {
    min-width: 20px;
    width: 20px;
    height: 20px;
  }
}

.sort-menu__container {
  padding: 10px;
  border-radius: 10px;
  background: $color_tertiary_gray;
}

.sort-menu__item {
  margin: 2px 0;
  padding: 8px 10px;
  border-radius: 10px;
  font-size: 16px;
  line-height: 22px;
  color: $color_primary_black;
  background: $color_white;
  transition: all 0.3s;
  cursor: pointer;

  &.active,
  &.active:hover {
    background: $color_primary_black;
    color: $color_white;
  }

  &:hover {
    background: $color_gray;
    color: $color_primary_black;
  }
}

@media (max-width: 1000px) {
  .sort-menu__anchor,
  .sort-menu__item {
    max-height: 38px;
  }
}

@media (max-width: $breakpoint_m) {
  .sort-menu__anchor,
  .sort-menu__item {
    font-size: 14px;
    line-height: 19px;
  }
}

@media (max-width: $breakpoint_s) {
  .sort-menu__anchor {
    max-width: 38px;
    justify-content: center;

    p {
      display: none;
    }

    .Icon {
      width: 20px;
      height: 20px;
    }
  }
}
