.header-menu-frame {
  height: 40px;
  display: flex;
  align-items: center;
  gap: 20px;

}

.header-menu__button {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 10px;
  cursor: pointer;
  transition: all .3s;

  img {
    width: 24px;
    height: 24px;
  }

  p {
    line-height: 98%;
    font-size: 16px;
    font-weight: 700;
  }

  &.sell {
    width: auto;
    padding: 0 14px 0 11px;
    color: #FFF;
    background: #DD2006;

    &:hover {
      background: #110b11;
    }
  }

  &.log-in {
    width: 93px;
    color: #DD2006;
    border: 2px solid #DD2006;

    &:hover {
      border: 2px solid #110b11;
      color: #110b11;
    }
  }
}

.header-menu__burger-button {
  display: none;
}

.header__user-menu {
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 40px;

  & li {
    position: relative;
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
}

.identificator-unread-message {
  position: absolute;
  width: 9px;
  height: 9px;
  top: 0;
  right: 0;
  background: #DD2006;
  border-radius: 1000px;
}

.profile-select-container {
  transition: all .2s;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background: #F0F0F0;
  border-radius: 10px;
  gap: 20px;
  font-family: "Noto Sans", serif;
}

.profile-select-item {
  cursor: pointer;
  padding: 8px 23px 8px 20px;
  border-radius: 10px;
  white-space: nowrap;
  transition: all .3s;

  & p {
    font-size: 16px;
    line-height: 22px;
    color: #110B11;
  }

  &:hover {
    background: #FFFFFF;
  }
}

.user-menu_container {
  padding: 144px 20px 20px 20px;

  display: flex;
  flex-direction: column;
  gap: 120px;


  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;
}

.menu_locale-currency__container {
  display: flex;
  flex-direction: column;

  &.show {
    gap: 10px;
  }
}

.user-buttons_container, .user-auth-buttons {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.user-auth-buttons {
  margin-bottom: 20px;
}

.icon-cross_container {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;

  $mouse-device: '(hover: hover) and (pointer: fine)';

  svg:hover {
    @media #{$mouse-device} {
      -webkit-animation-name: spin-180;
      -webkit-animation-duration: 500ms;
    }
  }

  @keyframes spin-180 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }

  svg {
    width: 24px;
    height: 24px;
  }
}

.user-button {
  width: 100%;
  padding: 5px;
  border-radius: 4px;

  cursor: pointer;
  display: flex;
  gap: 10px;

  transition: all .3s;

  box-sizing: border-box;

  svg {
    width: 24px !important;
    height: 24px;
    border-radius: 1000px;
  }

  .icon-box {
    height: 24px;
    position: relative;
    transition: all .3s;
    overflow: hidden;

    & div:nth-child(2) {
      transition: all .3s;
      position: absolute;
      top: 0;
      opacity: 0;
      visibility: hidden;
    }
  }

  &.active {
    background: #F2F2F2;
    color: #110B11;;

    .icon-box {
      & div:nth-child(1) {
        transition: all .3s;
        opacity: 0;
        visibility: hidden;
      }
      & div:nth-child(2) {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &.item-active {
    background: #F2F2F2;
    color: #110B11;;
  }

  &.item {
    padding-left: 15px;
  }

  .arrow {

    margin-left: auto;
    transition: all .3s;

    &.show-menu {
      transform: rotate(180deg);
    }
  }
}

.items-frame {
  overflow: hidden;
}

.items-container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  opacity: 0;
  margin-top: -120px;
  transition: all .3s;

  &.currency {
    margin-top: -80px;
  }

  &.show {
    opacity: 1;
    margin-top: 0;
  }
}

@media (max-width: 768px) {
  .header-menu__burger-button {
    position: relative;
    cursor: pointer;
    height: 24px;
    display: block;

    img {
      margin: auto 0;
      width: 24px;
      height: 24px;
    }
  }

  .header-menu__button.log-in, .header__user-menu {
    display: none;
  }
}

@media (max-width: 480px) {
  .header-menu-frame {
    gap: 10px;
  }
}
