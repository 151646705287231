.select-frame {
  max-width: 460px;
  width: 100%;
  max-height: 271px;

  position: relative;
  display: flex;
  flex-direction: column;

  @media (max-width: 530px) {
    min-width: 90%;
  }
}

.select-title {
  font-size: 16px;
  line-height: 130%;
  color: #110b11;
  opacity: 0.8;
  margin-bottom: 5px;
}

.select-input {
  height: 58px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  outline: none;
  padding: 0 50px 0 10px;
  border-radius: 10px;
  font-size: 16px;
  line-height: 130%;
  color: rgba(17, 11, 17, 0.8);
  transition: all 0.5s;


  &::placeholder {
    opacity: 0.3;
  }
}

.select-icon-container {
  width: 24px;
  height: 24px;
  border-radius: 50%;

  position: absolute;

  top: 42.335px;
  right: 20px;

  cursor: pointer;
  transition: transform 0.3s, background-color 0.4s ease-in-out;
  transform: rotate(90deg);

  svg {
    width: 24px;
    height: 24px;
  }

  &.open {
    transform: rotate(270deg);
  }
}

.select-dropdown-frame {
  margin-top: 96px;
  position: absolute;
  width: 100%;
  visibility: hidden;
  z-index: 1;
  opacity: 0;
  transition: all .3s;

  &.show {
    visibility: visible;
    opacity: 1;
    z-index: 1;
  }
}

.select-dropdown-container::-webkit-scrollbar {
  width: 12px;
}

.select-dropdown-container::-webkit-scrollbar-track {
  background: #F2F2F2;
}

.select-dropdown-container::-webkit-scrollbar-thumb {
  background-color: #FFFFFF;
  border-radius: 20px;
  border: 4px solid #F2F2F2;
}

.select-dropdown-container {
  max-height: 250px;
  overflow-y: auto;
  padding: 10px;
  background: #f0f0f0;
  border-radius: 10px;
  scroll-behavior: unset;
}

.select-dropdown-item {
  margin-bottom: 2px;
  padding: 9px 10px;
  border-radius: 10px;
  background: transparent;
  border: 1px solid transparent;
  transition: all 0.3s;
  font-size: 16px;
  line-height: 22px;
  color: #110b11;
  cursor: pointer;

  &.active {
    border: 1px solid #ffffff;
    background: #ffffff;
  }

  &:hover {
    border: 1px solid #F9F9F9;
    background: #F9F9F9;
  }

  &.empty {
    cursor: text;
    color: rgba(17, 11, 17, 0.2);

    &:hover {
      border: 1px solid transparent;
      background: transparent;
    }
  }
}
