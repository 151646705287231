.post-card-carousel {
  width: 220px;
  overflow: hidden;

  .unique-post-swiper .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px !important;
    height: 220px !important;
  }

  .unique-post-swiper img {
    border-radius: 0 10px 10px 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .unique-post-swiper .swiper-button-next,
  .unique-post-swiper .swiper-button-prev {
    transition: 0.3s all ease-in-out;
    opacity: 0;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .unique-post-swiper .swiper-button-next::after,
  .unique-post-swiper .swiper-button-prev::after {
    font-size: 18px;
  }

  .unique-post-swiper .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    background-color: #fff;
    margin: 0 5px;
    opacity: 0;

    transition: 0.3s all ease-in-out;
  }

  .unique-post-swiper .swiper-pagination-bullet-active {
    width: 10px;
    height: 10px;
    background-color: #fff;
    opacity: 0;

    transition: 0.3s all ease-in-out;
  }
}

@media (max-width: 1000px) {
  .post-card_frame {
    &.is-filter {
      .post-card-carousel {
        width: 100%;

        .unique-post-swiper .swiper-slide {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100% !important;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .post-card-carousel {
    width: 210px;

    .unique-post-swiper .swiper-slide {
      width: 210px !important;
    }
  }
}

@media (max-width: 480px) {
  .post-card-carousel {
    width: 167px;

    .unique-post-swiper .swiper-slide {
      width: 167px !important;
      height: 150px !important;
    }
  }
}
