@import 'src/style/default_variables';

.post-card_frame {
  min-width: 220px;
  width: 220px;
  height: 325px;

  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;

  background: #f5f5f5;
  border-radius: 10px;
  border: 2px solid #f5f5f5;
  $mouse-device: '(hover: hover) and (pointer: fine)';

  text-decoration: none;

  &.is-my-posts {
    min-width: auto;
    width: 100%;
  }

  &:hover {
    @media #{$mouse-device} {
      .post-card-carousel {
        .unique-post-swiper .swiper-button-next,
        .unique-post-swiper .swiper-button-prev {
          opacity: 1;
        }

        .swiper-pagination-bullet,
        .swiper-pagination-bullet-active {
          opacity: 1;
        }
      }

      .post-card-price,
      .post-card-location,
      .post-card-username,
      .post-card-title {
        color: #dd2006;
      }
    }
  }
}

.post-card-image_container {
  width: 100%;
  height: 220px !important;
  border-radius: 10px 10px 0 0;
  border: 2px solid #f2f2f2;
  z-index: 2;
  overflow: hidden;
  position: relative;
}

.post-card-image_wrapper {
  height: 100%;

  svg,
  img,
  span {
    height: 100%;
    width: 100%;
    object-position: center !important;
    object-fit: cover !important;
    box-sizing: border-box !important;
  }
}

.post-card-content_container {
  padding: 3px 8px;
}

.post-card-price-location_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.post-card-price {
  min-width: 50px;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #110b11;
  transition: all 0.3s;

  &.discussed {
    font-size: 16px;
  }
}

.post-card-location {
  transition: all 0.3s;

  min-width: 50px;
  font-size: 12px;
  line-height: 16px;
  color: #110b11;
  font-weight: 400;
}

.post-card-title {
  transition: all 0.3s;

  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  color: #110b11;

  display: -webkit-box;
  overflow: hidden;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  max-height: 44px;
  min-height: 44px;
}

.post-card-user-info_container {
  display: flex;
  align-items: center;
  padding-top: 8px;
  gap: 4px;
}

.post-card-user-avatar {
  max-width: 16px;
  width: 100%;
  height: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  border: 1px solid rgb(0, 0, 0, 0.05);
  background: #f2f2f2;
  box-sizing: border-box;

  & span {
    font-weight: 500;
    font-size: 8px;
    line-height: 11px;
    color: #dd2006;
  }
}

.post-card-state_container {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 2;
}

.post-card-state {
  min-width: 40px;
}

.post-card-username {
  transition: all 0.3s;

  width: 100%;

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #110b11;

  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
}

.post-card__chip {
  padding: 5px 8px;
  border-radius: 20px;

  background-color: #99d6a6;
  color: #017b1c;

  font-weight: 600;
  line-height: 16px;
  font-size: 12px;

  &.used {
    color: #d96804;
    background-color: #f8d7a9;
  }
}

@media (max-width: 1000px) {
  .post-card_frame {
    &.is-filter {
      min-width: auto;
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .post-card_frame {
    min-width: 210px;
    width: 210px;
    height: 325px;
  }

  .post-card-price {
    font-size: 16px;

    &.discussed {
      font-size: 13px;
    }
  }

  .post-card-image_container {
    height: 210px !important;
  }
}

@media (max-width: 480px) {
  .post-card_frame {
    min-width: 167px;
    width: 167px;
    height: 243px;
  }

  .post-card-location {
    min-width: auto;
  }

  .post-card__chip {
    line-height: 12px;
    font-size: 8px;
  }

  .post-card-image_container {
    height: 150px !important;
  }

  .post-card-title {
    font-size: 14px !important;
    line-height: 20px !important;
    min-height: 40px !important;
  }

  .post-card-user-info_container {
    padding: 0;
  }
}
