@import 'src/style/default_variables';

.avatar-update-modal-content {
  max-width: 440px;
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.upload-square {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  height: 267px;
  border: 1px dashed rgba(17, 11, 17, 0.6);
  border-radius: 10px;
  transition: all 0.3s;

  svg {
    width: 24px;
    height: 24px;
  }

  &.pressed-file,
  &:hover {
    border: 1px dashed $color_accent_red;
    background: $color_white;
  }
}

.upload-square-text {
  max-width: 280px;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: $color_primary_black;
  opacity: 0.8;
  transition: all 0.2s;

  span {
    color: #2f80ed;
    text-decoration: underline;
  }
}

.edit-avatar-square {
  width: 440px;
  height: 320px !important;
  border-radius: 10px;
  position: relative;
  background: #817e81;
  transition: height 0.3s !important;
}

.cont-crop {
  width: 440px;
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReactCrop__crop-selection {
  border: 1px solid $color_white !important;
}

.ReactCrop {
  border-radius: 10px !important;
}

.avatar-image {
  max-height: 320px !important;
  object-fit: contain;
  border-radius: 10px;
}

.avatar-crop {
  object-position: center;
  object-fit: cover;
}

.slider-crop {
  max-width: 340px;
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 0;
}

.upload-button {
  width: 100%;
  height: 60px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  background: rgba(17, 11, 17, 0.2);

  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: $color_white;
  text-align: center;
  cursor: pointer;

  &.active {
    &:hover {
      background: $color_primary_black;
    }

    cursor: pointer;
    background: $color_accent_red;
  }
}

.box-file-info_frame {
  box-sizing: border-box;
  background: $color_white;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  margin-top: -60px;
}

.box-file-info_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  transition: all 0.3s;
}

.box-file-root-info {
  display: flex;
  gap: 10px;
  align-items: center;
  max-height: 44px;
  overflow: hidden;

  svg {
    width: 44px;
    height: 44px;
  }
}

.file-name-size {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .title {
    transition: all 0.3s;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: #110b11;
    opacity: 0.8;
  }

  .size {
    font-weight: 300;
    font-size: 14px;
    line-height: 130%;
    color: #110b11;
    opacity: 0.8;
  }
}

.delete-avatar_container {
  display: flex;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}

@media (max-width: $breakpoint_m), (max-height: $breakpoint_m) {
  .avatar-update-modal-content {
    gap: 40px;
    margin: 0 auto;
  }

  .box-file-info_frame {
    margin-top: -20px;
  }

  .cont-crop {
    width: auto;
  }

  .edit-avatar-square {
    max-width: 440px;
    max-height: 320px;
    width: 100%;
    height: 100%;
  }

  .upload-button {
    font-size: 16px;
    line-height: 130%;
  }
}

@media (max-width: 550px) {
  .upload-square-text {
    padding: 0 5px;
  }
}
