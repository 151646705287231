@import "../../../style/default_variables";

.input-container {
  max-width: 460px;
  width: 100%;

  position: relative;
  transition: all 0.2s ease-out;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.input,
textarea {
  height: 58px;
  background: $color_white;
  border: 1px solid #f0f0f0;
  outline: none;
  padding: 0 0 0 10px;
  border-radius: 10px;
  font-size: 16px;
  line-height: 130%;
  color: rgba(17, 11, 17, 0.8);
  transition: all 0.5s;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    opacity: 0.3;
  }

  &.error {
    border: 1px solid $color_accent_red;
  }

  &.passwords {
    &::placeholder {
      transition: all 0.5s;
      opacity: 0;
    }

    &.show-placeholder::placeholder {
      opacity: 0.3;
    }
  }
}

.textarea {
  box-sizing: border-box;
  height: 134px;
  padding: 10px;
  resize: none;
}

.textarea::-webkit-scrollbar {
  width: 12px;
}

.textarea::-webkit-scrollbar-track {
  background: $color_white;
}

.textarea::-webkit-scrollbar-thumb {
  background-color: $color_tertiary_gray;
  border-radius: 20px;
  border: 4px solid $color_white;
}

.error-message-container {
  position: absolute;
  margin-top: 60px;
  z-index: -1;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;

  &.visible {
    transition: all 0.2s ease-out;
    margin-top: 85px;
    opacity: 1;
    visibility: visible;
  }

  &.textareaError {
    margin-top: 160px;
    transition: all 0.3s;
  }
}

.error-message {
  font-size: 14px;
  line-height: 160%;
  color: $color_accent_red;
}

.input-icon-container {
  position: absolute !important;
  right: 20px;
  top: 40px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  opacity: 0.8;
  transition: all 0.2s;
}

.eyeHide {
  position: absolute !important;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

.eyeShow {
  position: absolute !important;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

.pass-rules-container {
  position: absolute;
  z-index: -1;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  margin-top: 26px;
  gap: 0;
  opacity: 0;

  div {
    margin-bottom: -5px;
  }

  &.show {
    gap: 5px;
    opacity: 1;
    margin-top: 96px;

    & div {
      margin-bottom: 0;
    }
  }
}

.rule {
  transition: all 0.4s;
  max-height: 24px;
  margin-left: 4px;
  opacity: 0.8;
  color: #110b11;
  display: flex;
  align-items: center;

  li {
    opacity: 0.8;
    list-style-type: none;
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    padding-right: 12.97px;
    margin-left: 8px;
    font-size: 30px;
  }

  svg {
    padding-right: 5px;
  }

  div {
    height: 24px;
    padding-top: 1px;
  }

  &.error {
    color: $color_error_red;
  }

  &.successful {
    color: $color_success_green;
    display: flex;
  }
}
