@import 'src/style/default_variables';

.chips-container_frame {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.chip_frame {
  min-width: 50px;
  height: 35px;
  margin-right: 20px;
  margin-bottom: 10px;

  &.hide {
    min-width: 0;
    width: 0;
    height: 0;
    margin: 0;
    visibility: hidden;
    opacity: 0;
  }
}

.chip {
  height: 35px;
  padding: 7px 7px 7px 20px;

  display: flex;
  align-items: center;

  border-radius: 10px;
  border: 1px solid $color_primary_black;
  transition: all 0.3s;
  cursor: pointer;

  .Icon {
    margin-left: 15px;
  }

  .chip-price-icon {
    margin-left: 0;
    margin-right: 5px;
  }

  &:hover {
    color: $color_accent_red;
    background: transparent;
    border: 1px solid $color_accent_red;
    text-decoration: line-through;
  }

  svg {
    color: $color_accent_red;
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
}

.chip-text {
  font-size: 14px;
  font-weight: 600;
}

.clear-all-filters__container {
  height: 35px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;

  p {
    border-bottom: 1px solid $color_primary_black;
  }
}

@media (max-width: 1200px) {
  .chips-container_frame {
    margin-bottom: 16px;
  }
}
