@import 'src/style/default_variables';

.passwords_frame {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 40px;
  justify-content: space-between;
  transition: all 0.3s;
}

.absent-email {
  max-width: 440px;
  margin: auto auto;
  text-align: center;
  font-size: 24px;
  font-family: Noto Sans;
  font-style: normal;
  line-height: 130%;
  color: $color_primary_black;

  span {
    cursor: pointer;
    color: $color_accent_red;
  }
}

.passwords_inputs_container {
  max-width: 460px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: relative;
  transition: all 0.3s;

  &.show {
    gap: 123px;
  }
}

.confirm-pass_frame {
  position: relative;
}

.confirm-pass-rule_container {
  position: absolute;
  z-index: -1;
  transition: all 0.3s;
  margin-top: -30px;
  opacity: 0;

  &.show {
    opacity: 1;
    margin-top: 10px;
  }
}

@media (max-width: 1060px) {
  .passwords_frame {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: start;
  }
}

@media (max-width: $breakpoint_m) {
  .absent-email {
    font-size: 20px;
  }
}
