@import '../../../style/default_variables';

.deal-modal__container {
  width: 700px;

  padding: 50px 80px;
  border-radius: 10px;

  background: $color_tertiary_gray;

  display: flex;
  flex-direction: column;
  gap: 40px;

  transition: 0.3s all;

  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  .deal-modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .close-modal {
      width: 24px;
      height: 24px;

      img {
        cursor: pointer;
      }
    }
  }

  .deal-modal__buttons-container {
    width: 100%;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;
    gap: 20px;

    transition: all 0.3s;
  }

  .deal-button {
    width: 45%;
    padding: 18px 0;

    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    text-align: center;

    border-radius: 10px;

    transition: all 0.3s;

    cursor: pointer;

    &.cancel {
      background: $color_primary_black;
      opacity: 0.2;
      color: $color_white;

      border: 1px solid;

      &:hover {
        background: $color_primary_black;
        opacity: 0.3;
      }
    }

    &.next {
      display: grid;
      place-items: center;

      background: $color_accent_red;
      border: 1px solid $color_accent_red;
      color: #ffffff;

      &:hover {
        color: $color_accent_red;
        background: transparent;
      }
    }
  }

  .deal-modal__title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 40px;

    u {
      color: $color_accent_red;
    }
  }

  .deal-modal__main-options {
    display: flex;
    gap: 20px;
  }

  .deal-modal__additional-options {
    margin-left: 30px;

    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .deal-modal__textarea {
    resize: none;
    border-radius: 10px;
    border: none;

    padding: 10px;
    width: 100%;
    height: 160px;
  }

  .deal-modal__select-buyer-container {
    height: 370px;
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 20px;

    p {
      font-size: 18px;
    }

    .deal-modal__input-container {
      display: flex;
      align-items: center;
      position: relative;

      input {
        width: 100%;

        padding: 10px 40px 10px 10px;
        border-radius: 10px;
        border: 1px solid #ffffff;

        background: #ffffff;

        color: #3c3c3b8c;
        font-size: 14px;
        font-weight: 400;

        &:focus {
          border: 1px solid #110b11;
          outline: rebeccapurple;
        }
      }

      .search-icon {
        position: absolute;
        right: 10px;
      }
    }

    .deal-modal__select-radio-group {
      display: flex;
      flex-direction: column;
      gap: 10px;

      max-height: 370px;

      overflow-y: scroll;
    }
  }
}

@media (max-width: 700px) {
  .deal-modal__container {
    padding: 25px;

    max-width: unset;
    max-height: unset;
    width: 100%;
    height: 100%;

    border-radius: 0;

    .deal-modal__buttons-container {
      margin-top: auto;
    }
  }
}
