@import 'src/style/default_variables';

.default__component-chip {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 7px 20px;
  gap: 10px;

  border-radius: 10px;
  border: 1px solid $color_primary_black;
  transition: 0.2s linear;

  cursor: pointer;
  white-space: nowrap;
  user-select: none;

  &:hover {
    scale: 1.001;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }

  &.active {
    border-color: $color_accent_red;
    background-color: $color_accent_red;
    color: $color_white;
  }
}
