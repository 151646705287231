@import '../../../../style/default_variables';

@mixin catalog-overlap($class: 'disabled') {
  .overlap_#{$class} {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 200vh;
    z-index: 6;
    background: rgba(35, 31, 32, 0.75);
    opacity: 0;
    display: block;
    visibility: hidden;
    transition: opacity 0.15s, visibility 0s linear 0.1s;
    backdrop-filter: blur(1px);
    @content;
  }
}

@mixin catalog-frame($class: 'disabled') {
  .catalog-content_frame_#{$class} {
    min-width: 320px;
    position: absolute;
    z-index: 6;
    left: 0;
    overflow: hidden;
    width: 100%;
    opacity: 0;
    visibility: hidden;

    transition: transform 0.3s ease, opacity 0ms linear 0.1s, visibility 0ms linear 0.3s;
    transform-origin: top center;
    transform: scaleY(0);
    @content;
  }
}

@include catalog-overlap();
@include catalog-overlap('active') {
  transition: opacity 0.2s ease, visibility 0s;
  opacity: 1;
  visibility: visible;
  overflow-y: hidden;
}

@include catalog-frame();
@include catalog-frame('active') {
  transform: scaleY(1);
  transition: transform 0.3s ease, opacity 0ms, visibility 0ms;
  opacity: 1;
  visibility: visible;
}

.catalog-content_container {
  height: 100%;
  width: 100%;
  max-width: 1420px;
  min-width: 320px;
  margin: 0 auto !important;
  border-radius: 0 0 10px 10px;
  background: $color_background;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.catalog-navigation {
  position: relative;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
}

@media (max-width: $breakpoint_m) {
  .catalog-content_container {
    padding-top: 80px;
  }
}

@media (max-width: $breakpoint_s) {
  .catalog-content_container {
    padding-top: 80px;
  }
}
