@import "src/style/default_variables";

.review__post {
  display: flex;
  flex-direction: column;

  padding: 40px 0;
  border-bottom: 2px solid #e5e5e5;
  border-radius: 2px;

  width: 100%;
  gap: 20px;

  &:hover {
    box-shadow: 0 0 10px 0px #ececec;
  }

  &:nth-child(1) {
    border-top: 2px solid #e5e5e5;
  }

  .review__user {
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: $color_primary_black;

    .review__image {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      margin-right: 20px;
    }

    .review__info {
      .reviewer__name {
        font-size: 16px;
        font-weight: 500;
      }

      .review__date {
        font-size: 14px;
        font-weight: 400;
        opacity: 0.8;
      }
    }

    .review-user-info__avatar {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 45px;
      height: 45px;
      border-radius: 50%;
      margin-right: 20px;

      border: 1px solid rgb(0, 0, 0, 0.05);
      background: #f2f2f2;

      font-weight: 500;
      color: $color_accent_red;

      font-size: 16px;
      line-height: 22px;
    }
  }

  .reviewer__comment {
    font-size: 16px;
    font-weight: 400;
    opacity: 0.8;
  }
}
