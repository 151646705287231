@import 'src/style/default_variables';

.reviews__frame {
  display: flex;
  flex-direction: column;

  gap: 20px;

  .reviews__rating {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  .reviews__member {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
  }
}

@media (max-width: $breakpoint_m) {
  .reviews__frame {
    .reviews__rating {
      justify-content: center;
    }
  }
}
