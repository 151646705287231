.posting-rules {
  max-width: 1240px;
  //user-select: none;

  .posting-rules__rule-box {
    padding: 30px;
    background-color: #fafafa;
    margin-bottom: 30px;
    border-radius: 10px;

    .posting-rules__rule-title {
      text-align: center;
      margin-bottom: 30px;
    }

    .posting-rules__rule-subtitle {
      margin-bottom: 20px;
    }
  }
}
