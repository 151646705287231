.filter-page__container {
  margin: 40px auto 0;
  min-width: 320px;
  width: 100%;
  padding: 0 20px;
  scroll-behavior: smooth;
}

.filter-page {
  width: 100%;
  max-width: 1420px;
  margin: 0 auto;
}

.filter-page__content {
  margin-top: 40px;
  display: flex;
  justify-content: flex-start;
  gap: 50px;
}

.button-to-up-window__container {
  position: fixed;
  bottom: 40px;
  right: 40px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;

  svg {
    width: 24px;
    height: 24px;
    transform: rotate(270deg);
  }

  &.show {
    visibility: visible;
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .filter-page__container, .filter-page__content {
    margin-top: 20px;
  }
}

@media (max-width: 480px) {
  .filter-page__container {
    padding: 0 14px;
  }
}
