@import 'src/style/default_variables';

.my-purchase-card {
  border-radius: 10px;
  padding: 10px 20px;
  background: $color_white;
  transition: box-shadow 0.2s;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  button {
    font-family: 'Noto Sans', sans-serif;
  }

  &:hover {
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  }
}

.my-purchase-card-content {
  border-bottom: 1px solid $color_tertiary_gray;
  padding-bottom: 10px;
  cursor: pointer;

  img {
    width: 100%;
    height: 200px;
    background: #3c3c3b;
    border-radius: 10px;
    object-position: center;
    object-fit: cover;
  }

  .price {
    margin-top: 6px;
    font-weight: 500;
    font-size: 18px;
  }

  .title {
    margin-top: 4px;
    font-size: 16px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.my-purchase-card-footer {
  padding-top: 14px;
  border-top: 1px solid $color_tertiary_gray;


  .purchased-at {
    font-size: 14px;
    opacity: 0.7;
  }

  button {
    margin-top: 20px;
    border: 2px solid $color_accent_red;
    width: 100%;
    padding: 8px 20px;
    background: transparent;
    border-radius: 10px;

    color: $color_accent_red;
    font-weight: bold;
    font-size: 14px;

    &.view-review {
      color: $color_white;
      background: $color_primary_black;
      opacity: 0.2;
      border-color: $color_tertiary_gray;
    }
  }
}
