.banners__swiper {
  cursor: pointer;

  .swiper {
    width: 100%;
    max-width: 1420px;
  }

  .swiper-slide {
    width: 100% !important;
    height: 100% !important;
    overflow: hidden;
    border-radius: 10px;
    display: unset !important;

    text-align: center;
    font-size: 18px;
    background: #fff;
  }

  .swiper-slide-active {
    border-radius: 0 0 10px 10px !important;
  }

  .swiper-pagination-bullet-active-main {
    background-color: #817E81;
    width: 50px;
    height: 2px;
    border-radius: 20px
  }

  .swiper-pagination-bullet {
    background-color: #817E81;
    width: 30px;
    height: 2px;
    border-radius: 10px
  }

  .swiper-slide-next {
    opacity: 1;
  }

  .swiper-slide-prev {
    opacity: 1;
  }

  @media (max-width: 1480px) {
    .swiper {
      max-width: 97%;
    }
  }

  @media (max-width: 1000px) {
    .swiper {
      max-width: 95%;
    }
  }

  @media (max-width: 760px) {
    .swiper-slide-active {
      border-radius: 10px 10px 10px 10px !important;
    }
  }
}
