.header-history-frame {
  width: 100%;
  transition: all 0.25s;
}

.header-history-container {
  width: 100%;
  min-height: 38px;
  height: fit-content;
  padding: 6px;
  border: 1px solid #f0f0f0;
  background: #ffffff;
  border-radius: 10px;
  margin-top: -47px;
  margin-left: -7px;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
  box-sizing: unset;

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

.search-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 40px;
  transition: all 0.3s;

  &.show-history {
    padding-top: 52px;
  }
}

.titles {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;

  & p {
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    color: #110b11;
    opacity: 0.8;
  }

  & p:last-child {
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    text-decoration-line: underline;
    color: #dd2006;
    opacity: 0.8;
    cursor: pointer;
    text-align: end;
  }
}

.search-item,
.search-error-message {
  min-height: 24px;
  padding: 0 10px;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-out;

  & p {
    width: 100%;
    font-size: 16px;
    line-height: 130%;
    color: #110b11;
    opacity: 0.8;
  }

  &:hover {
    background: #f0f0f0;
  }
}

.search-error-message {
  font-size: 14px;
  line-height: 130%;
  color: #dd2006;
  cursor: default;
  opacity: 0;
  margin-top: -34px;
  transition: all 0.3s;

  &.show {
    margin-top: 0;
    opacity: 1;
  }

  &:hover {
    background: transparent;
  }
}

@media (max-width: 1000px) {
  .header-history-container {
    max-width: none;
  }
}
