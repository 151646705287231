@import 'src/style/default_variables';

.selector__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;

  max-width: 460px;
  width: 100%;

  border-radius: 10px;
  background: #f9f9f9;
  transition: height 1s ease-in-out;
  position: relative;

  margin-bottom: 40px;

  .selector__container-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    width: 100%;
    height: 50px;
    padding: 10px 25px;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    cursor: pointer;
    transition: all 1s ease-in-out;
    line-height: 22px;
    color: #110b11;
    z-index: 3;

    .selector-arrow {
      transition: 0.3s;

      &.selector-arrow-up {
        transition: 0.3s;
        rotate: -180deg;
      }
    }
  }
}

.selector__option-active {
  background: #ffffff;

  &:hover {
    background: #ffffff !important;
  }
}

.selector__select {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: transparent;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  width: 100%;
  border-radius: 0 0 10px 10px;
  gap: 5px;

  &.show__selector__select {
    padding: 10px 0;
  }

  &:focus {
    outline: none;
  }

  .selector__option {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: start;
    box-sizing: border-box;
    flex-direction: row;
    padding: 10px 15px;
    width: 95%;
    border-radius: 10px;

    font-size: 16px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    position: relative;
    margin-top: -55px;
    z-index: -1;
    opacity: 0;

    svg {
      visibility: visible;
    }

    &.show__selector__select {
      opacity: 1;
      transition: all 0.3s ease-in-out;
      margin-top: 0;
      z-index: 1;
    }

    &:hover {
      background-color: #ffffff;
    }

    .selector__option__title {
      color: $color_primary_black;
      justify-content: flex-start;

      font-size: 16px;
      font-weight: 500;
      text-align: center;

      margin-left: 10px;
    }
  }
}
