@import '../../../style/default_variables.scss';

.user-info__wrapper {
  max-width: 360px;
  width: 100%;

  .user-info__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .user-info__avatar {
      display: flex;
      align-items: center;
      justify-content: center;

      max-width: 180px;
      width: 100%;
      min-width: 180px;
      height: 180px;

      border-radius: 50%;
      border: 1px solid rgb(0, 0, 0, 0.05);
      background: #f2f2f2;

      & h1 {
        font-weight: 500;
        color: #dd2006;

        font-size: 32px;
        line-height: 33px;
      }
    }

    .user-info__root-info-container {
      display: flex;
      flex-direction: column;
      gap: 20px;
      flex-basis: 200%;

      .user-info__username-container {
        display: flex;
        gap: 15px;
        align-items: center;

        .username {
          font-size: 24px;
          font-weight: 700;
        }

        .location-container {
          display: flex;
          align-items: center;
          gap: 5px;
          opacity: 0.8;

          font-size: 13px;
          font-weight: 500;
          color: $color_secondary_gray;
          white-space: nowrap;
        }
      }
    }

    .user-info__buttons-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .user-info__interaction-container {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        gap: 10px;
      }

      .user-info__date {
        color: $color_primary_black;

        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
      }

      p {
        font-size: 12px;
        font-weight: 400;
        color: $color_primary_black;
      }
    }

    .user-info__products-sold {
      display: flex;
      width: 245px;
      align-items: center;
      font-size: 12px;
      color: $color_secondary_gray;

      img {
        margin-right: 7px;
      }
    }
  }
}

.small-btn-with-icon {
  min-width: 35px;
  min-height: 35px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  background-color: $color_tertiary_gray;
}

.gamar-button__style {
  width: 100%;
  min-width: 160px;
  padding: 0 5px;
  height: 35px;
  align-items: center;
}

.subscribed_style {
  background: #110b1133;
}

.bell-icon {
  position: absolute;
  right: 31%;
}

.user-info__menu-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #110b11;
  height: 28px;
  background: #f0f0f0;
  border-radius: 10px;
  border-color: transparent;
  transition: 0.2s;
  font-size: 16px;

  &:hover {
    background: #ffffff;
    transition: 0.2s;
  }

  &:focus {
    border: transparent;
    outline: transparent;
  }
}

@media (max-width: $breakpoint_m) {
  .user-info__wrapper {
    margin: 0 auto;

    max-width: 470px;
    width: 100%;

    .user-info__container {
      flex-direction: column;
      align-items: center;

      .user-info__avatar {
        max-width: 200px;
        width: 100%;
        min-width: 200px;
        height: 200px;
      }

      .user-info__root-info-container .user-info__username-container {
        justify-content: center;
        flex-direction: column;
        gap: 20px;
      }
    }
  }
}
