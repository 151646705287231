@import '../../../style/default_variables';

.Icon {
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    flex: 1;
    height: auto;
  }

  *:hover {
    transition: all 0.2s;
    &.accent-red {
      stroke: $color_accent_red;
    }
  }
}

$sizes: (
  'extra-small': 8px,
  'small': 12px,
  'medium-small': 16px,
  'medium': 20px,
  'medium-large': 24px,
  'large': 30px,
  'extra-large': 32px,
  '2x-large': 40px,
  '3x-large': 48px,
  '4x-large': 56px,
  '5x-large': 64px,
  '6x-large': 72px,
  '7x-large': 80px,
  '8x-large': 88px,
  '9x-large': 96px,
  '10x-large': 104px,
  '11x-large': 112px,
  '12x-large': 120px,
  '13x-large': 128px,
  '14x-large': 136px,
  '15x-large': 144px,
  '16x-large': 152px,
  '17x-large': 160px,
  '18x-large': 168px,
  '19x-large': 176px,
  '20x-large': 184px,
);

@each $name, $dimension in $sizes {
  .Icon-IconSize__#{$name} {
    width: $dimension;
    height: $dimension;
  }
}

.first:hover ~ .second {
  background-color: blue;
}
