.footer__container {
  width: 100%;
  min-width: 320px;
  background: #3c3c3b;
  padding: 40px 20px;
  margin-top: 100px;
}

.footer {
  width: 100%;
  max-width: 1420px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.footer__logo {
  img {
    width: 170px;
    height: 60px;
  }
}

.footer__content-container {
  display: flex;
  flex-wrap: wrap;
  gap: 80px;
}

.footer__section {
  display: flex;
  flex-direction: column;
  gap: 12px;

  p,
  a {
    color: #f5f5f5;
    font-size: 14px;
    text-decoration: none;
  }

  a {
    cursor: pointer;
  }

  p.title {
    font-weight: 700;
  }
}

.footer__icons-col {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer__icons-container {
  display: flex;
  gap: 20px;

  a {
    height: fit-content;
  }

  img {
    width: 36px;
    height: 36px;
  }
}

@media (max-width: 1420px) {
  .footer__content-container {
    gap: 40px;
  }

  .footer__container {
    margin-top: 60px;
  }

  .footer__section {
    p,
    a {
      font-size: 14px;
    }
  }

  .footer__logo {
    img {
      width: 120px;
      height: 40px;
    }
  }

  .footer__icons-container img {
    width: 36px;
    height: 36px;
  }
}

@media (max-width: 1000px) {
  .footer__container {
    margin-top: 40px;
  }
}

@media (max-width: 768px) {
  .footer__content-container {
    order: 3;
  }

  .footer__section {
    p,
    a {
      font-size: 12px;
    }
  }

  .footer__icons-container {
    order: 2;
  }
}

@media (max-width: 480px) {
  .footer__container {
    padding: 40px 14px;
  }

  .footer__content-container {
    gap: 14px;
  }

  .footer__section {
    p,
    a {
      font-size: 10px;
    }
  }
}
