@import 'src/style/default_variables';

.user-profile__wrapper {
  min-width: 320px;

  .user-profile-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 120px;

    max-width: 1460px;
    padding: 0 20px;
    margin: 40px auto 0;
  }
}

@media (max-width: $breakpoint_m) {
  .user-profile__wrapper .user-profile-container {
    flex-direction: column;
  }
}
