@import 'src/style/default_variables';

.top-sellers__background {
  padding: 100px 20px 82px;
  background: #F1F3F5;
}

.top-sellers__wrapper {
  max-width: 1420px;
  margin: 0 auto;
  gap: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.top-sellers-card {
  flex: 1 1 49%;
  padding: 30px;
  background: $color_white;
  border-radius: 20px;
  border: 1px solid $color_tertiary_gray_dark;
}

.top-sellers-card__title{
  font-size: 28px;
}

.top-sellers-card__text {
  font-size: 16px;
  margin-top: 10px;
  text-align: left;
}

.top-sellers-card__info-icon {
  display: block;
  margin: 0 16px 20px auto;
}

.seller-row {
  padding: 7px 20px;
  background: $color_background;
  border-radius: 10px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto auto auto 1fr;
  place-items: center;

  &[data-place = '0'] {
    margin-bottom: 20px;
    .seller-row__place {
      color: $color_accent_red;
    }
  }

  &[data-place = '1'] {
    margin-bottom: 20px;
    .seller-row__place {
      color: $color_secondary_gray;
    }
  }

  &[data-place = '2'] {
    .seller-row__place {
      color: #ADADAD;
    }
  }
}

.seller-row__place {
  font-size: 32px;
  font-weight: bold;
}

.seller-row__avatar {
  display: grid;
  place-items: center;
  min-width: 45px;
  width: 45px;
  height: 45px;
  margin-left: 20px;
  border: 1px solid $color_tertiary_gray_dark   ;
  border-radius: 100%;
  overflow: hidden;
  background: $color_tertiary_gray;
  color: $color_accent_red;
  cursor: pointer;
  text-decoration: none;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }

  p {
    display: block;
    height: 21px;
    font-size: 24px;
  }
}

.seller-row__name {
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
  color: $color_primary_black
}

.seller-row__review-statistic {
  margin-left: auto;
  text-align: end;

  .rating__stars-view {
    justify-content: end;
  }
}

.seller-row__open-reviews-span {
  display: inline-block;
  margin-top: 10px;
  color: $color_accent_red;
  font-size: 15px;
  cursor: pointer;
}

.top-sellers-banner__image {
  width: 100%;
  max-width: 310px;
  max-height: 210px;
  float: right;
  margin-left: 30px;
  margin-bottom: 10px;
}

@media (max-width: $breakpoint_xl) {
  .top-sellers-card__title {
    font-size: 24px;
  }
}

@media (max-width: $breakpoint_l) {
  .top-sellers-card {
    flex: 1 1 50%;
  }
}

@media (max-width: $breakpoint_m) {
  .top-sellers__background {
    padding: 60px 20px;
  }

  .top-sellers-card__info-icon {
    margin-bottom: 10px;
  }

  .top-sellers-card {
    padding: 16px;
  }

  .top-sellers-card__title {
    font-size: 20px;
  }

  .top-sellers-card__text {
    font-size: 14px;
  }

  .seller-row {
    flex-wrap: wrap;

    &[data-place = '0'] {
      margin-bottom: 10px;
    }

    &[data-place = '1'] {
      margin-bottom: 10px;
    }
  }

  .seller-row__place {
    font-size: 24px;
  }

  .seller-row__name {
    font-size: 14px;
  }

  .seller-row__review-statistic {
    .Icon {
      width: 20px;
      height: 20px;
    }
  }

  .seller-row__open-reviews-span {
    font-size: 14px;
  }

  .top-sellers-banner__image {
    margin-left: 0;
  }
}

@media (max-width: $breakpoint_s) {
  .top-sellers-card {
    padding: 14px;
  }

  .top-sellers-card__info-icon {
    margin-top: 4px;
  }

  .top-sellers-card__title {
    font-size: 18px;
  }

  .seller-row {
    padding: 6px 10px;
    grid-auto-flow: unset;
    grid-template-columns: auto auto 1fr;
    grid-template-rows: auto 1fr;
    place-items: center start;
  }

  .seller-row__place, .seller-row__avatar, .seller-row__name {
    grid-row: 1;
  }

  .seller-row__review-statistic {
    margin: 4px 0 0 auto;
    grid-column: span 3;
    text-align: center;

    .Icon {
      width: 16px;
      height: 16px;
    }
  }
}
