@import '../../style/default_variables';

i {
  font-style: italic;
}

.chat__container {
  min-width: 320px;
  max-width: 1460px;
  width: 100%;
  height: calc(100vh - 100px);
  padding: 20px;
  margin: auto;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;

  .chat__close-btn {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}

.chat__button-arrow-to-up {
  width: 40px;
  height: 40px;
  background: #f2f2f2;
  box-shadow: 1px 1px 1px #110b11;
  position: absolute;
  border-radius: 50%;
  z-index: 1;
  bottom: 125px;
  right: 40px;
  transition: all 0.3s;
  margin-right: -110px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;
    transform: rotate(90deg);
  }

  &.show {
    opacity: 1;
    margin-right: 0;
  }
}

.chat__chat-room-container {
  height: unset;
  min-width: 0;
  width: 100%;
  display: flex;
  flex-direction: column;

  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  border-right: 1px solid #f2f2f2;
  border-radius: 0 10px 10px 0;
  overflow: hidden;

  .chat__chat-room-messages {
    width: 100%;
    height: 100%;
    padding: 0 20px;
    overflow: auto;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #f2f2f2;
      border-radius: 10px;
      border: 3px solid #f2f2f2;
    }

    .chat__no-chat-room-message {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Noto Sans', sans-serif;
      font-size: 24px;
      color: #110b11;
    }

    .chat__chat-room-message {
      padding-top: 14px;
      display: flex;
      flex-direction: column;
      position: relative;

      &.post-existing {
        padding-top: 69px;
      }

      .chat__chat-room-message-divider {
        width: 100%;
        height: 2px;
        background: #f0f0f0;
        margin: 10px 0 30px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          background-color: #f0f0f0;
          border-radius: 15px;
          padding: 10px;
          font-family: 'Noto Sans', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 19px;
          color: #000000;
          opacity: 0.7;
          margin: 0 10px;
        }
      }

      .chat__chat-room-message__from-me {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-content: center;
        align-items: center;
        margin-bottom: 20px;
        position: relative;

        a {
          color: #dd2006;
        }

        .chat__user-avatar__container {
          margin-top: auto;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          box-sizing: border-box;
          overflow: hidden;
        }

        .chat__chat-room-message__from-me-image {
          width: 60px;
          height: 60px;
          display: flex;
          align-items: center;
          border-radius: 50%;
        }

        .chat__chat-room-message__from-me__text {
          word-break: break-all;
          cursor: pointer;
          position: relative;
          max-width: 300px;
          background: #110b11;
          border-radius: 10px 10px 10px 10px;
          padding: 10px;
          color: #ffffff;

          font-family: 'Noto Sans', sans-serif;
          font-size: 14px;
          line-height: 19px;
          margin-right: 20px;

          &.is-deleted {
            cursor: default;
          }

          &.hide-arrow {
            &:after {
              display: none;
            }
          }

          .chat__chat-room-message__from-me__time {
            font-family: 'Noto Sans', sans-serif;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            flex-direction: column;
            align-items: end;
            justify-content: center;
            color: white;
            opacity: 0.7;
          }
        }

        .chat__chat-room-message__from-me__text:after {
          content: '';
          position: absolute;
          bottom: -7px;
          right: -11px;
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-right: 22px solid #110b11;
          transform: rotate(214deg);
        }
      }

      .chat__user-avatar {
        display: flex;
        align-items: center;
        max-width: 60px;
        width: 100%;
        min-width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 1px solid rgb(0, 0, 0, 0.05);
        justify-content: center;
        background: #f2f2f2;
        cursor: pointer;

        & h1 {
          font-weight: 500;
          font-size: 24px;
          line-height: 33px;
          color: #dd2006;
        }
      }

      .chat__chat-room-message__from-other {
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 20px;
        position: relative;

        a {
          color: #dd2006;
        }

        .chat__user-avatar__container {
          margin-top: auto;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          box-sizing: border-box;
          overflow: hidden;
        }

        .chat__chat-room-message__from-other-image {
          width: 60px;
          height: 60px;
          display: flex;
          align-items: center;
          border-radius: 50%;
          cursor: pointer;
        }

        .chat__chat-room-message__from-other__text {
          word-break: break-all;
          cursor: pointer;

          position: relative;
          max-width: 300px;
          background: #f2f2f2;
          border-radius: 10px 10px 10px 10px;
          padding: 10px;

          color: #000;

          font-family: 'Noto Sans', sans-serif;
          font-size: 14px;
          line-height: 19px;
          margin-left: 20px;

          &.is-deleted {
            cursor: default;
          }

          &.hide-arrow {
            &:after {
              display: none;
            }
          }

          .chat__chat-room-message__from-other__time {
            font-family: 'Noto Sans', sans-serif;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;
            justify-content: end;
            color: #000000;
            opacity: 0.7;
          }
        }
      }

      .chat__chat-room-message__from-other__text:after {
        content: '';
        position: absolute;
        bottom: -7px;
        left: -10px;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 22px solid #f2f2f2;
        transform: rotate(-34deg);
      }
    }
  }

  .chat__choose-chat-text {
    font-family: 'Noto Sans', sans-serif;
    margin: auto;
    font-size: 18px;
    line-height: 25px;
    color: #110b11;
  }

  textarea {
    border-radius: 0 !important;
  }

  .chat__chat-room-send-message__frame {
    width: 100%;
    padding: 0 20px 20px;
    box-sizing: border-box;

    @media (max-width: 768px) {
      bottom: 0;
      padding: 0 10px;
    }
  }

  .chat__chat-room-input-container {
    width: 100%;

    display: flex;
    flex-direction: column;
    border: 1px solid #f2f2f2;
    border-radius: 10px;
    background-color: white;

    box-sizing: border-box;

    .chat__chat-room-send-message {
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: space-between;

      box-sizing: border-box;

      &.reply-bar-active {
        border: 1px solid #f2f2f2;
        border-radius: 0px 0px 10px 10px;
      }

      .chat__chat-room-send__input-submit {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #dd2006;
        width: 40px;
        height: 40px;
        border: none;
        cursor: pointer;
        margin-right: 20px;

        img {
          width: 20px;
          height: 20px;
        }
      }

      .chat__chat-room-send__input-submit:disabled {
        background: rgba(17, 11, 17, 0.2);
      }
    }
  }
}

.chat__chat-room-message-from-me-menu {
  position: absolute;
  margin: 15px 0;
  top: 100%;
  right: 5%;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
}

.chat__msg-edit-menu {
  .chat__edit-button {
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #110b11;
    width: 170px;
    height: 28px;
    background: #f0f0f0;
    border-radius: 10px;
    border-color: transparent;
    transition: 0.2s;

    &:hover {
      background: #ffffff;
      transition: 0.2s;
    }

    &:focus {
      border: transparent;
      outline: transparent;
    }

    img {
      padding: 10px 10px;
    }
  }
}

.chat__close-selected-chat {
  margin-left: auto;

  svg {
    cursor: pointer;
    width: 32px;
    height: 32px;
  }
}

.chat__chat-room-user {
  width: 100%;
  height: 69px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;

  background: #f0f0f0;
  border-bottom: 1px solid #f2f2f2;
  box-sizing: border-box;

  .chat__post-container {
    width: fit-content;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .chat__image-container {
    width: 58px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 4px;
    overflow: hidden;
  }

  img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }

  .chat__post-data {
    margin-left: 10px;

    display: flex;
    flex-direction: column;
    font-family: 'Noto Sans', sans-serif;
    color: #110b11;

    .chat__post-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 120%;
    }

    .chat__post-price {
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.chat__text-link {
  text-decoration: none;
  text-underline: #2f80ed;
  color: #2f80ed;
}

.active_chat {
  background-color: #f2f2f2;
}

.none {
  display: none;
}

.chat__users-list-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.resizer-l {
  position: absolute;
  cursor: col-resize;
  height: 100%;
  right: 0;
  top: 0;
  width: 1px;
  color: black;
}

.reply-box__container {
  width: 100%;
  display: flex;
  justify-content: center;

  .chat__reply-box {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background: #f2f2f2;

    .image {
      padding-right: 10px;
    }

    img:last-child {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }

    .text-box {
      max-width: 950px;
      width: 100%;

      .username {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        align-items: center;
        color: #110b11;
      }

      .text {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: #110b11;
      }
    }

    .icon {
      padding: 0 20px;
    }
  }
}

.delete-modal-content {
  padding: 30px 30px;
  border-radius: 15px;
  background-color: #ffffff;
  max-width: 460px;
  width: 100%;
  height: 234px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
  box-sizing: border-box;

  p {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #110b11;
    max-width: 300px;
  }

  .delete-message-menu_container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .delete-message-menu_button {
      padding: 10px 44px;
      border-radius: 10px;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      cursor: pointer;
      transition: all 0.3s;
      margin: 10px;
      border: 1px solid $color_accent_red;

      &.delete {
        color: $color_accent_red;
        background: transparent;

        &:hover {
          border: 1px solid $color_primary_black;
          color: $color_primary_black;
        }
      }

      &.cancel {
        color: $color_white;
        background: $color_accent_red;

        &:hover {
          border: 1px solid $color_primary_black;
          background: $color_primary_black;
        }
      }
    }
  }
}

.reply-on-message-box,
.reply-on-opponent-message-box {
  padding: 5px 10px;
  max-width: 168px;
  width: fit-content;

  background: rgba(17, 11, 17, 0.3);
  border-radius: 10px;
  margin-right: 85px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 5px;

  .username {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }

  .text {
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }
}

.reply-on-message-box:after {
  position: absolute;
  content: '';
  top: 0;
  width: 1px;
  height: 100%;
  right: -5px;
  background: rgba(17, 11, 17, 0.3);
}

.reply-on-opponent-message-box {
  margin-left: 85px;
  margin-right: 0;
}

.reply-on-opponent-message-box:after {
  position: absolute;
  content: '';
  top: 0;
  width: 1px;
  height: 100%;
  left: -5px;
  background: rgba(17, 11, 17, 0.3);
}

.unread-message-indicator {
  width: 10px;
  height: 10px;
  border-radius: 1000px;
  margin-right: 6px;
  background-color: #dd2006;

  &.oponent {
    margin-left: 6px;
    margin-right: 0;
  }
}

.upload-images-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 0 18px 20px;
  opacity: 0.4;

  img {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
}

.message-image-container {
  display: flex;
  flex-direction: column;
  gap: 5px;

  img {
    max-width: 280px;
    object-position: center;
    object-fit: cover;
    border-radius: 5px;
  }
}

@media (max-width: 1420px) {
  .chat__container {
    height: calc(100vh - 80px);
  }
}

@media (max-width: 768px) {
  .chat__container {
    flex-direction: column;
    padding: 0;
    height: calc(100vh - 68px);

    &.selected-chat {
      padding: 0;
      height: 100%;
    }
  }

  .delete-modal-content {
    padding: 20px;
  }

  .chat__chat-room-container {
    width: 0;
    height: 0;
    visibility: hidden;
    border: none;
    border-radius: 10px;

    &.show {
      width: 100%;
      height: 100vh;
      visibility: visible;
    }
  }

  .chat__button-arrow-to-up {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 20px;
    right: 14px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .chat__chat-room-container .chat__chat-room-messages {
    padding: 10px;
  }

  .chat__chat-room-send-message {
    margin-bottom: 10px !important;
  }

  .chat__chat-room-user {
    top: 0;
    z-index: 1;
    padding: 0 10px;
  }

  .chat-avatar {
    width: 0 !important;
    height: 0 !important;
    opacity: 0;
    visibility: hidden;
  }

  .chat__chat-room-message__from-other__text {
    margin-left: 10px !important;
  }

  .chat__chat-room-message__from-me__text {
    margin-right: 10px !important;
  }

  .reply-on-opponent-message-box {
    margin-left: 16px;
  }

  .reply-on-message-box {
    margin-right: 16px;
  }
}

@media (max-width: 480px) {
  .delete-modal-content {
    height: 100%;
    border-radius: 0;
  }
}
