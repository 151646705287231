.image-card {
  position: relative;
  width: 100px;
  height: 70px;
  border-radius: 10px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.21);

  img {
    height: 100%;
    width: 100%;
    object-position: center;
    object-fit: cover;
    position: relative;
    color: transparent;
  }

  svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
}

.image-card.last {
  max-width: 700px;
  width: 100%;
  height: 340px;

  svg {
    cursor: pointer;
    width: 44px;
    height: 44px;
  }
}

.image-card.last .overlay {
  label, div {
    width: 44px;
    height: 44px;
    margin: auto 0;
  }
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .2s ease-in-out;
  background: linear-gradient(0deg, rgba(240, 240, 240, 0.5), rgba(240, 240, 240, 0.5));
  border-radius: 10px;
  display: flex;
  gap: 20px;
  justify-content: center;

  label, div {
    width: 24px;
    height: 24px;
    margin: auto 0;
  }
}

.image-card:hover .overlay {
  opacity: 1;
}
