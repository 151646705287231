@import "src/style/default_variables";

.root-post-info_frame {
  width: 48%;
  transition: all .3s;
}

.root-post-info_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.root-post-info_bottom-menu {
  box-sizing: border-box;
  padding: 0 20px 20px 20px;
  background-color: #fff;
  position: fixed;
  z-index: 1010;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(100%);
  box-shadow: inset 0 1px 0 0 #ededed;
  transition: transform .35s ease-in-out;

  &.show {
    transform: translateY(0);
  }
}

@media (max-width: 1420px) {
  .root-post-info_frame {
    width: 100%;
  }

  .root-post-info_container {
    gap: 10px;
  }
}

@media (max-width: 480px) {
  .root-post-info_container {
    gap: 6px;
  }
}
