@import 'src/style/default_variables';

.reviews-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.reviews-modal {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  width: 100%;
  height: 100%;
  max-width: 620px;
  max-height: 850px;
  gap: 40px;
  padding: 50px;

  border-radius: 10px;

  transition-duration: 0.3s;
  transition-property: background-color;
  transition-timing-function: ease-in-out;

  outline: none;
  background-color: #f5f5f5;
  overflow: hidden;
}

.reviews-modal-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-shrink: 0;
}

.reviews-modal-header__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
}

.reviews-modal-header__close {
  cursor: pointer;
}

.reviews-modal-body {
  width: 100%;

  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: start;
  overflow: auto;
}

@mixin breakpoint_m {
  @media (max-width: $breakpoint_m) {
    .reviews-container {
      margin-top: 0;
      padding: 0;
      gap: 0;

      .reviews-modal {
        width: 100%;
        height: 100%;
        max-width: unset;
        max-height: unset;
        padding: 20px;
        border-radius: 0;
      }
    }
  }
}
