@import 'src/style/modalStyles';
@import 'src/style/default_variables';

.post-card-frame {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px 20px;
  background: $color_white;
  border-radius: 10px;
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  }
}

.post-card-main-info {
  display: flex;
  cursor: pointer;
}

.post-card-image {
  width: 320px;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  transition: all 0.2s;
  flex-shrink: 0;
  position: relative;

  img {
    height: 100%;
    width: 100%;
    object-position: center;
    object-fit: cover;
    position: relative;
    z-index: 1;
    color: transparent;
  }
}

.post-card-content {
  width: 100%;
  min-height: 100%;
  min-width: 0;
  margin-left: 30px;
  color: $color_primary_black;
  display: flex;
  justify-content: space-between;

  .left-content {
    .post-price {
      font-size: 18px;
      margin-bottom: 4px;
    }

    .post-title,
    .post-description {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      transition: all 0.2s;
    }

    .post-title {
      margin-bottom: 12px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-word;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .post-description {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      line-height: 1.3em;
    }
  }

  .right-content {
    margin-top: 10px;
  }
}

.post-card-footer {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.published-at {
  font-size: 14px;
  opacity: 0.7;
}

.post-card-action-buttons {
  display: flex;
  align-items: center;
  gap: 20px;

  button {
    background: $color_tertiary_gray;
    border-radius: 10px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 16px;
    font-weight: 600;
    transition: padding 0.2s;

    .Icon {
      transition: all 0.2s;
    }
  }
}

@media (max-width: 768px) {
  .post-card-content {
    margin-left: 20px;

    .left-content {
      .post-title,
      .post-description {
        font-size: 14px;
      }

      .post-description {
        -webkit-line-clamp: 3;
      }
    }

    .right-content {
      display: none;
    }
  }

  .post-card-image {
    width: 210px;
    height: 140px;
  }

  .post-card-footer {
    margin-top: 14px;
  }

  .post-card-action-buttons {
    button {
      padding: 10px;
    }

    p {
      display: none;
    }
  }
}

@media (max-width: $breakpoint_s) {
  .post-card-frame {
    margin-bottom: 16px;
  }

  .post-card-image {
    width: 180px;
    height: 120px;
  }

  .post-card-content {
    .left-content {
      .post-description {
        -webkit-line-clamp: 2;
      }
    }
  }

  .published-at {
    font-size: 12px;
  }

  .post-card-action-buttons {
    gap: 10px;

    button {
      padding: 8px;

      .Icon {
        width: 20px;
        height: 20px;
      }
    }
  }
}
