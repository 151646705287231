@import "src/style/default_variables";

.notice-block {
  width: 100%;
  padding: 22px 32px;
  background: $color_white;
  margin-bottom: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 16px;

  div {
    display: flex;
    flex-wrap: wrap;
  }

  a {
    color: $color_accent_red;
  }

  img {
    width: 24px;
    height: 24px;
  }
}

@media (max-width: 768px) {
  .notice-block {
    padding: 10px;
    font-size: 12px;
  }
}
