@import 'src/style/default_variables';

.filter-options_frame {
  min-width: 400px;
  height: fit-content;
  padding: 20px 30px;
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 40px;
  background: $color_tertiary_gray;
  border-radius: 10px;

  &.is-swiper {
    min-width: auto;
    flex-direction: column;
    gap: 20px;
    padding-top: 60px;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  .filter-options__cross-container {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 30px;
  }
}

.filter-menu__title {
  color: $color_primary_black;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
  min-width: 316px;
  display: flex;

  svg {
    cursor: pointer;
  }

  &.close-button {
    position: absolute;
    top: 20px;
    justify-content: end;
  }
}

.show-more-options {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 25px;
  padding: 7px 15px;
  border: 1px solid $color_light_gray;
  border-radius: 8px;
  font-size: 16px;
  color: $color_primary_black;
  cursor: pointer;
  position: relative;
  width: 100%;
}

.show-more-options:hover {
  background-color: $color_tertiary_gray_dark;
}

.right-arrow-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
}

.popup-container {
  position: absolute;
  background-color: $color_white;
  border: 1px solid $color_tertiary_gray;
  border-radius: 8px;
  box-shadow: 0 0 25px 0 #0000000D;
  z-index: 100;
  padding: 20px 11px 26px 30px;
  top: 0;
  left: 355px;
  box-sizing: border-box;
  width: 400px;
}

.popup-content {
  padding-left: 10px;
  max-width: 360px;
  max-height: 196px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  &::-webkit-scrollbar {
    width: 3px;
    height: 100px;
  }

  &::-webkit-scrollbar-track {
    background: $color_white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color_tertiary_gray_dark;
    border-radius: 10px;
    border: 2px solid transparent;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $color_secondary_gray;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}

.right-arrow-icon {
  margin-left: 8px;
}

.location-item {
  flex: 0 1 calc(50% - 16px);
  gap: 10px;
}

.location-item .MuiFormControlLabel-root {
  gap: 10px;
}

@media (max-width: 1000px) {
  .filter-options_frame {
    display: none;

    &.is-swiper {
      display: flex;
    }
  }

  .show-more-options {
    position: relative;
  }

  .popup-container {
    position: absolute;
    width: 100%;
    max-width: 370px;
    top: 45px;
    left: 0;
  }

  .popup-container .popup-content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .right-arrow-icon {
    transform: rotate(90deg);
    margin-top: -6px;
  }
}

