@import "../../../style/default_variables";

.buyer-radio-box {
  padding: 10px 20px;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  border-radius: 10px;
  background: #FFFFFF;

  .user-avatar {
    display: flex;
    align-items: center;
    justify-content: center;

    max-width: 45px;
    width: 100%;
    min-width: 45px;
    height: 45px;

    border-radius: 50%;
    border: 1px solid rgb(0, 0, 0, 0.05);
    background: #F2F2F2;

    & h1 {
      font-weight: 500;
      color: #DD2006;

      font-size: 24px;
      line-height: 33px;
    }
  }

  .username-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 5px;

    height: 100%;

    .username {
      font-size: 16px;
      font-weight: 500;
    }

    .message {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .date {
    align-self: start;
    margin-left: auto;

    font-size: 14px;
    font-weight: 400;
  }
}

@media (max-width: $breakpoint_s) {
  .buyer-radio-box .date {
    display: none;
  }
}
