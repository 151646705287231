.gamar-loader-box {
  margin-right: 70px;
  margin-bottom: 11px;
}

.gamar-loader {
  display: inline-block;
  position: absolute;
}

.gamar-loader div {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #dd2006;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.gamar-loader.white div {
  background: #ffffff;
}

.gamar-loader div:nth-child(1) {
  left: 8px;
  animation: gamar-loader1 0.6s infinite;
}

.gamar-loader div:nth-child(2) {
  left: 8px;
  animation: gamar-loader2 0.6s infinite;
}

.gamar-loader div:nth-child(3) {
  left: 32px;
  animation: gamar-loader2 0.6s infinite;
}

.gamar-loader div:nth-child(4) {
  left: 56px;
  animation: gamar-loader3 0.6s infinite;
}

@keyframes gamar-loader1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes gamar-loader3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes gamar-loader2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
