@import 'src/style/default_variables';

.my-posts__container {
  max-width: 1460px;
  min-width: 320px;
  min-height: 798px;
  width: 100%;
  margin: 40px auto 0;
  padding: 0 20px;
  box-sizing: border-box;
}

.my-posts__sort-tools__container {
  width: 100%;
  margin: 40px 0;
  display: flex;
  gap: 20px;
  justify-content: end;
  transition: all 0.3s;
}

.toggle-view-container {
  display: flex;
  align-items: center;
  background: $color_white;
  border: 1px solid $color_tertiary_gray;
  border-radius: 10px;
  overflow: hidden;
}

.list-view-button,
.grid-view-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 50%;
  height: 100%;
  cursor: pointer;
  transition: background 0.2s;

  img {
    width: 20px;
    height: 20px;
  }

  &.active {
    background: $color_tertiary_gray;
  }
}

.list-view-button {
  border-right: 1px solid $color_tertiary_gray;
}

.my-posts__cards-container {
  width: calc(100% + 16px);
  margin: 0 -8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

@media (max-width: 768px) {
  .my-posts__container {
    margin-top: 20px;
  }

  .my-posts__sort-tools__container {
    margin: 20px 0;
  }
}

@media (max-width: 546px) {
  .MuiTabs-flexContainer {
    justify-content: normal !important;
  }
}
