.personal-info_frame {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  gap: 140px;
  transition: all .3s;
}

.personal-info_inputs_container {
  max-width: 460px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: relative;
  transition: all .3s;

  &.show {
    gap: 123px
  }
}

@media(max-width: 1060px) {
  .personal-info_frame {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    gap: 40px
  }
}
