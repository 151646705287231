@import 'src/style/default_variables';

.swiper-slide {
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-button-lock {
  display: block !important;
}

.zoom-swiper__modal-container {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1100;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;

  &.show {
    opacity: 1;
    visibility: visible;
    outline: none;
  }
}

.zoom-swiper__wrapper {
  width: 100%;
  height: 100%;
  padding: 62px 0 88px;

  &:hover {
    .button-slide-prev__zoom-swiper,
    .button-slide-next__zoom-swiper {
      opacity: 1;
    }
  }
}

.zoom-swiper__header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 62px;
  padding: 0 40px;
  display: flex;
  align-items: center;
  z-index: 5;
  transition: all 0.3s;
}

.zoom-swiper__header_counter {
  min-width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  flex-shrink: 0;
  color: $color_white;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.zoom-swiper__header__title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0 16px;
  flex-shrink: 1;
  min-width: 0;
  color: $color_white;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  transition: all 0.3s;
}

.zoom-swiper__header__title_price {
  display: inline-block;
  white-space: nowrap;
  margin-right: 16px;
  font: inherit;
}

.zoom-swiper__header__title_text {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: -4px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.zoom-swiper__header__buttons {
  display: flex;
  align-items: center;
  margin-right: 24px;
}

.zoom-swiper__header_button-close {
  cursor: pointer;
  display: inline-flex;
  outline: none;

  margin-left: auto;

  padding: 5px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.6);

  svg {
    width: 30px;
    height: 30px;
  }
}

.zoom-swiper__container {
  height: 100%;
}

.zoom-swiper-slide {
  width: 100% !important;
  height: 100% !important;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.zoom-swiper-image {
  width: fit-content !important;
  transition: all 0.3s;
  max-width: 80%;
}

.zoom-swiper__custom-pagination {
  width: 100%;
  height: 48px;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  gap: 10px;
  transition: all 0.3s;

  .swiper-pagination-bullet {
    width: 100%;
    max-width: 32px;
    padding: 4px;
    background: $color_tertiary_gray_dark;
    display: inline-flex;
    align-items: center;
    flex-shrink: 1;
    outline: none;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.3s ease-in-out;
    border-radius: 0;

    &.swiper-pagination-bullet-active {
      opacity: 1;
    }
  }
}

@media (max-width: $breakpoint_m) {
  .zoom-swiper-image {
    max-width: 95%;
  }

  .zoom-swiper__header {
    padding: 0 20px;
  }
}

@media (max-width: 520px) {
  .zoom-swiper__header__title {
    visibility: hidden;
    opacity: 0;
  }
}
