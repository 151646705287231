// Media points
$breakpoint_xl: 1420px;
$breakpoint_l: 1200px;
$breakpoint_m: 760px;
$breakpoint_sm: 560px;
$breakpoint_s: 460px;

// Default colors
$color_primary_black: #110b11;
$color_secondary_gray: #3c3c3b;
$color_tertiary_gray: #f2f2f2;
$color_white: #ffffff;
$color_accent_red: #dd2006;
$color_background: #f9f9f9;
$color_tertiary_gray_dark: #e7e7e7;
$color_gray: #e3e3e3;
$color_light_gray: rgb(192, 192, 192);
$color_tertiary_gray_light: #f3f3f3;

// Default error colors
$color_success_green: #23a942;
$color_error_red: #e3262f;