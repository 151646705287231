@import 'src/style/default_variables';

.posts-owner-info_frame {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.posts-owner-info_bottom-menu {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 20px;
  background-color: $color_white;
  position: fixed;
  z-index: 5;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(100%);
  box-shadow: inset 0 1px 0 0 #ededed;
  transition: all 0.3s ease-in-out;
}

.post-owner-avatar_frame,
.call-message-buttons_frame {
  display: flex;
  align-items: center;
  gap: 20px;
  transition: all 0.3s;
}

.post-owner-avatar {
  cursor: pointer;
  min-width: 60px;
  width: 60px;
  height: 60px;
  background: $color_tertiary_gray_dark;
  border-radius: 1000px;
  overflow: hidden;
  display: grid;

  img {
    width: 60px;
    height: 60px;
  }

  & p {
    margin: auto;
    font-weight: 500;
    font-size: 24px;
    line-height: 33px;
    color: $color_accent_red;
  }
}

.post-owner-name {
  cursor: pointer;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
    color: $color_primary_black;
}

.call-message-button {
  max-height: 40px;
  padding: 8px 20px;
  border-radius: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;

  transition: all 0.3s;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;

  text-decoration: none;

  svg {
    width: 24px;
    height: 24px;
  }

  &.call {
    border: 2px solid $color_accent_red;
    background: transparent;
    color: $color_accent_red;
  }

  &.message {
    border: 2px solid $color_accent_red;
    background: $color_accent_red;
    color: $color_white;

    &:hover {
      border: 2px solid $color_primary_black;
      background: $color_primary_black;
      color: $color_white;
    }
  }

  .show-full-number {
    font-size: 12px;
    line-height: 100%;
  }
}

@media (max-width: $breakpoint_xl) {
  .posts-owner-info_frame {
    display: none;
  }

  .posts-owner-info_bottom-menu {
    transform: translateY(0);

    &.is-my-post {
      transform: translateY(100%);
    }
  }

  .call-message-buttons_frame {
    padding-left: 20px;
    width: 70%;
  }

  .call-message-button {
    line-height: 22px;
    margin-left: auto;
    display: flex;
    justify-content: center;
    width: 50%;
    padding: 8px 0;
  }
}

@media (max-width: 640px) {
  .call-message-buttons_frame {
    margin-right: 0;
    width: fit-content;
  }

  .call-message-button {
    padding: 8px 20px;

    p {
      display: none;
    }

    &.show-number {
      width: auto;

      p {
        display: block;
      }
    }
  }
}

@media (max-width: $breakpoint_s) {
  .post-owner-name {
    display: none;
  }

  .call-message-buttons_frame {
    width: 100%;
  }
}
