.blog-feed-container {
  padding: 50px 20px;
  width: 100%;
  margin-top: 60px;
}

.blog-feed-content {
  max-width: 1420px;
  margin: 0 auto;
}

.blog-feed-title-container {
  display: inline-flex;
  align-items: center;
  text-decoration: none;

  h3 {
    cursor: pointer;
  }

  div {
    margin-left: 10px;
    rotate: -90deg;
  }
}

.blog-feed-row {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 16px;
}

@media (max-width: 1000px) {
  .blog-feed-container {
    margin-top: 40px;
  }
}

@media (max-width: 600px) {
  .blog-feed-row {
    grid-template-columns: 1fr;
  }
}
