@import 'src/style/default_variables';

.confirm-modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 80px;
  max-width: 460px;
}

.confirm-modal-message {
  font-size: 16px;
  line-height: 130%;
  color: $color_primary_black;
  opacity: 0.8;
  transition: all 0.2s;

  span {
    color: $color_accent_red;
  }
}

.input-code_container {
  display: flex;
  gap: 40px;
  align-items: center;
}

.input-code {
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;

  input {
    min-width: 80px;
    width: 100%;
  }
}

.send-again-text__container {
  display: flex;
  font-size: 16px;
  line-height: 100%;
  text-decoration-line: none;
  color: $color_primary_black;
  opacity: 0.6;
  gap: 6px;
}

.send-again-button {
  transition: color, border-bottom-color 0.2s;
  border-bottom: 1px solid transparent;

  &.pointer {
    cursor: pointer;
    color: $color_accent_red;

    &:hover {
      border-bottom: 1px solid $color_accent_red;
    }
  }

  &:hover {
    border-bottom: 1px solid $color_primary_black;
  }
}

.confirm-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  background: rgba(17, 11, 17, 0.2);
  border-radius: 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: $color_white;
  transition: all 0.3s;

  &.active {
    &:hover {
      background: $color_primary_black;
    }

    cursor: pointer;
    background: $color_accent_red;
  }
}

@media (max-width: $breakpoint_m), (max-height: $breakpoint_m) {
  .confirm-modal-content {
    margin: 0 auto;
    gap: 40px;
    position: initial;
  }

  .confirm-button {
    font-size: 16px;
    line-height: 130%;
  }

  .send-again-text__container,
  .confirm-modal-message {
    p {
      font-size: 14px;
    }
  }
}

@media (max-width: $breakpoint_s) {
  .input-code_container,
  .send-again-text__container {
    flex-direction: column;
    align-items: start;
  }
}
