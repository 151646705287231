.otp-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  width: 100%;

  user-select: none;

  input {
    text-align: center;

    padding: 0;
    width: 60px;
    height: 60px;

    font-size: 24px;

    border-radius: 10px;
    border: 2px solid transparent;
    user-select: none;
    outline: none;

    transition: all 0.15s ease-in-out;

    &:focus {
      border: 2px solid #dd2006;
    }
  }
}

@media (max-width: 400px) {
  .otp-container {
    justify-content: center;
    flex-wrap: nowrap;

    input {
      margin: 3px !important;
      width: 50px;
      height: 50px;
    }
  }
}

@media (max-width: 360px) {
  .otp-container {
    input {
      margin: 3px !important;
      width: 45px;
      height: 45px;
    }
  }
}

@media (max-width: 315px) {
  .otp-container {
    flex-wrap: wrap;

    input {
      margin: 3px !important;
      width: 40px;
      height: 40px;
    }
  }
}
