@import "src/style/default_variables";

.post-used-price_frame {
  display: flex;
  align-items: center;
}

.post-price {
  font-weight: 600;
  font-size: 28px;
}

.post-used {
  margin-left: 20px;
  font-size: 16px;
  line-height: 22px;

  &.used {
    color: #F2994A;
  }
  &.not-used {
    color: #219653;
  }
}

@media (max-width: 1420px) {
  .post-price {
    font-weight: 400;
    font-size: 24px;
  }
}

@media (max-width: 768px) {
  .post-price {
    font-size: 20px;
  }

  .post-used {
    font-size: 14px;
  }
}
